@import 'constants';

$module: 'NavItemWithSubNav';

.#{$module} {
  &__menu-item {
    // to override default styles from @material-ui/core/MenuItem
    height: 36px !important;
    min-width: 167px !important;

    #{&}--active#{&}--mobile {
      background-color: $purple-xdark !important;
      width: 150px;
    }
  }
}
