@import 'constants';

$module: 'Popover';

.#{$module} {
  .Popover-body {
    background: $black;
    border-radius: $border-radius;
    border: 1px solid $black;
    font-size: $fs14;
    max-width: 248px;
    text-align: center;
    padding: $s16 $s24;
    white-space: pre-line;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.11);
    box-sizing: border-box;
  }

  &--white,
  &--white-auto-width {
    .Popover-body {
      background-color: white;
      color: $black;
      padding: 0;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
      border: 1px solid $gray-light;
    }

    .Popover-tip {
      fill: $white;
    }

    .Popover-tipShape {
      stroke: $gray-light;
    }
  }

  &--white-auto-width {
    .Popover-body {
      max-width: none;
    }
  }

  &--blue,
  &--blue-auto-width {
    .Popover-body {
      background-color: $blue-lightest;
      border: 1px solid $blue-light;
      border-top: 1px solid $blue-lightest;
      color: $black;
    }

    .Popover-tip {
      fill: $blue-lightest;
      margin-left: -1px;
    }

    .Popover-tipShape {
      stroke: $blue-light;
      stroke-width: -1px;
    }
  }

  &--blue-auto-width {
    .Popover-body {
      max-width: none;
    }
  }
}
