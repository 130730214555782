@import 'constants';
@import 'mixins';

$module: 'Button';

.#{$module} {
  border: 1px solid $gray-medium;
  border-radius: $border-radius;
  box-sizing: border-box;
  font-weight: $fw600;
  font-size: $fs16;
  height: 40px;
  padding: 0 $s20;
  position: relative;
  transition: $duration !important;
  white-space: nowrap;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  cursor: pointer;

  &:hover {
    background-color: $gray-light;
    color: $neutral700;
  }

  &--loading {
    @include disabled-styles();
  }

  &--link {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__loading-indicator {
    @include transform-center();

    display: none;
    opacity: 0;
    transition-duration: $duration;

    &--show {
      display: block;
      opacity: 1;
    }
  }

  &__success-icon {
    @include transform-center();

    margin-top: $xxxs;
    opacity: 1;
  }

  &--large {
    font-size: $fs18;
    height: $s48;
    padding: 0 $s24;

    .#{$module}__icon {
      height: $s16;
      width: $s16;
    }
  }

  &--medium {
    font-size: $fs16;
    height: 40px;
    padding: 0 $s20;

    .#{$module}__icon {
      height: 14px;
      width: 14px;
    }
  }

  &--small {
    font-size: $fs16;
    height: $s32;
    padding: 0 $s16;

    .#{$module}__icon {
      height: 14px;
      width: 14px;
    }
  }

  &--mini {
    font-size: $fs14;
    height: $s24;
    padding: 0 $s12;

    .#{$module}__icon {
      height: $s12;
      width: $s12;
    }
  }

  &--xs {
    font-size: $fs12;
    height: $s24;
    padding: 0 $s12;

    .#{$module}__icon {
      height: $s12;
      width: $s12;
    }
  }

  &--auto {
    height: auto;
    padding: 0;
  }

  &--full-width {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &--unclickable {
    cursor: not-allowed;
  }

  &__icon {
    margin-top: -2px;

    &--left {
      margin-right: $s4;
    }

    &--right {
      margin-left: $s4;
    }
  }

  &--disabled {
    @include disabled-styles();

    &:hover,
    &:focus,
    &:active {
      @include disabled-styles();
    }
  }

  &--theme-link {
    @include button-link($purple, darken($purple, 10%));
  }

  // Only used as temporary theme for biller UI - do not reuse!!!
  &--theme-link-blue-medium {
    @include button-link($blue-medium, darken($blue, 10%));
  }

  &--theme-link-red {
    @include button-link($red, darken($red, 10%));
  }

  &--theme-link-purple {
    @include button-link($purple, darken($purple, 10%));
  }

  &--theme-link-purple400 {
    @include button-link($purple400, darken($purple400, 10%));
  }

  &--theme-link-gray {
    @include button-link($gray-dark, darken($gray-dark, 10%));

    font-weight: normal;
  }

  &--theme-link-light-gray {
    @include button-link($neutral300, darken($neutral300, 10%));

    font-weight: normal;
  }

  &--theme-link-navy-light {
    @include button-link($navy-light, $navy-light);

    font-weight: normal;
  }

  &--theme-link-white {
    @include button-link($white, $white);
  }

  &--theme-link-green {
    @include button-link($green, $green);
  }

  &--theme-link-navy {
    @include button-link($navy, $navy);

    font-weight: $fw400;
  }

  &--theme-primary {
    @include button-color-filled($white, $purple500, $purple700);
  }

  &--theme-primary-red {
    @include button-color-filled($white, $red, $red-orange);
  }

  &--theme-secondary-purple {
    @include button-color-outlined($purple, $white);
  }

  &--theme-secondary-purple-500 {
    @include button-color-outlined($purplePinkMedium, $white);
  }

  &--theme-secondary-purple-gray {
    border: 1px solid $gray-medium;
    color: $purple;
    padding: 0 8px;
    height: 30px;
    font-size: $fs14;

    &:focus,
    &:active,
    &:hover,
    &:visited {
      background-color: $primary050;
      border-color: $violet;
      color: $violet-xdark;
    }

    &:disabled {
      @include disabled-styles();
    }
  }

  &--theme-secondary-primary-050-gray {
    border: 1px solid $gray-dark;
    background-color: $primary050;
    color: $black;

    &:focus,
    &:active,
    &:hover,
    &:visited {
      background-color: $primary050;
      border-color: $gray-dark;
      color: $black;
    }

    &:disabled {
      @include disabled-styles();
    }
  }

  &--theme-secondary-purple-gray-medium-light {
    border: 1px solid $gray-medium-light;
    color: $purple;
    padding: 0 8px;
    height: 30px;
    font-size: $fs14;

    &:focus,
    &:active,
    &:hover,
    &:visited {
      background-color: $primary050;
      color: $violet-xdark;
    }

    &:disabled {
      @include disabled-styles();

      border: 1px solid $gray-medium-light;
    }
  }

  &--theme-primary-purple {
    @include button-color-filled($white, $primaryA1, $purple-dark);
  }

  &--theme-primary-blue {
    @include button-color-filled($white, $blue, $blue2);
  }

  &--theme-primary-black {
    @include button-color-filled($white, $navy, $navy);
  }

  &--theme-primary-violet {
    @include button-color-filled($purple-dark, $primary050, $primary050);

    height: 30px;
    border-color: $violet-light;
  }

  &--theme-primary-violet-generic {
    @include button-color-filled($purple-dark, $primary050, $primary050);

    border-color: $violet-light;
  }

  &--theme-primary-gray {
    @include button-color-filled($navy, $gray-medium, $gray-medium);
  }

  &--theme-secondary-blue {
    @include button-color-outlined($blue, $blue-duck-egg);
  }

  // Only used as temporary theme for biller UI - do not reuse!!!
  &--theme-tertiary-blue {
    @include button-color-filled($white, $blue-medium, $blue-steel);
  }

  &--theme-secondary-red {
    @include button-color-outlined($red, $gray-light);

    border-color: $gray-medium;

    &:hover {
      border-color: darken($gray-medium, 10%);
    }
  }

  &--theme-secondary-black {
    @include button-color-outlined($navy, $white);
  }

  &--theme-secondary-gray {
    @include button-color-outlined($gray-dark, $white);
  }

  &--theme-secondary-black-gray {
    border: 1px solid $gray-dark;
    color: $black;

    &:focus,
    &:active,
    &:hover,
    &:visited {
      border-color: $gray-dark;
      color: $black;
    }

    &:disabled {
      @include disabled-styles();
    }
  }

  &--theme-card {
    border-color: transparent;
    text-align: left;
    padding: 24px;
    white-space: normal;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
    height: 200px;
    width: 100%;

    &:hover {
      border-color: transparent;
      background-color: $primary050;
    }

    &:active {
      border-color: $purple;
    }

    &:disabled {
      @include disabled-styles();

      border-color: transparent;
      opacity: 1;
      box-shadow: none;
    }
  }

  &--theme-card-action {
    height: 100%;
    padding: 0;

    &:disabled {
      background-color: $primary050;
      border-color: $purple;
      box-shadow: none;
      color: $purple;
      opacity: 1;
      pointer-events: none !important;
    }
  }

  &__content {
    margin: 0 auto;
    opacity: 0;
    transition: opacity $duration;

    &--show {
      opacity: 1;
    }
  }

  &--no-right-border-radius {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &--no-left-border-radius {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &--no-border {
    border: 0;
  }

  &--no-hover-border {
    border: 0;

    &:hover {
      border: 0;
    }
  }

  &--square {
    height: 40px;
    width: 40px;
    padding: 0;

    &.#{$module}--large {
      height: 76px;
      padding: 16px 16px 6px;
      width: 76px;
    }

    &.#{$module}--selected {
      border-color: $purple;
    }
  }

  &--pill {
    border-radius: 999em;
    padding: 0 32px;
  }

  &--inline {
    display: inline;
  }
}
