@import 'constants';
@import 'mixins';

$module: 'RolesSelect';

.#{$module} {
  .Select-option {
    padding-left: 24px;

    &:first-child {
      padding-left: 12px;
    }

    &.is-disabled {
      padding-left: 12px;
      font-size: 12px;
      color: $neutral700;
    }
  }

  .Select-create-option-placeholder {
    border-bottom: 1px solid $border-color;
  }
}
