@import 'constants';
@import 'mixins';

$module: 'Box';

.#{$module} {
  transition: opacity $duration;

  &--tcenter {
    text-align: center;
  }

  &--tleft {
    text-align: left;
  }

  &--tright {
    text-align: right;
  }

  &--spacebetween {
    justify-content: space-between;
  }

  &--evenwidth > * {
    flex-basis: 0;
    flex-grow: 1;
    margin-right: $xxs;
  }

  &--row {
    display: flex;
    flex-direction: row;
  }

  &--rowreverse {
    display: flex;
    flex-direction: row-reverse;
  }

  &--column {
    display: flex;
    flex-direction: column;
  }

  &--columnreverse {
    display: flex;
    flex-direction: column-reverse;
  }

  &--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--wrapreverse {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  &--align-items-start {
    align-items: flex-start;
    display: flex;
  }

  &--align-items-end {
    align-items: flex-end;
    display: flex;
  }

  &--align-items-center {
    align-items: center;
    display: flex;
  }

  &--baseline,
  &--align-items-baseline {
    align-items: baseline;
    display: flex;
  }

  &--stretch,
  &--align-items-stretch {
    align-items: stretch;
    display: flex;
  }

  &--justify-content-start {
    display: flex;
    justify-content: flex-start;
  }

  &--justify-content-end {
    display: flex;
    justify-content: flex-end;
  }

  &--justify-content-center {
    display: flex;
    justify-content: center;
  }

  &--spacebetween,
  &--justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }

  &--spacebetween &--EmployeeCell__yes_add {
    z-index: 1;
  }

  &--spacearound,
  &--justify-content-space-around {
    display: flex;
    justify-content: space-around;
  }

  &--spaceevenly,
  &--justify-content-space-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  &--hide {
    display: none;
  }

  &--scrollX {
    overflow-x: auto;
  }

  &--scrollY {
    overflow-y: auto;
  }

  &--relative {
    position: relative;
  }

  &--absolute {
    position: absolute;
  }

  &--absoluteCover {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  &--fixed {
    position: fixed;
  }

  &--sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  &--none {
    display: none;
  }

  &--inline {
    display: inline;
  }

  &--block {
    display: block;
  }

  &--inlineBlock {
    display: inline-block;
  }

  &--borderbox {
    box-sizing: border-box;
  }

  &--shadow {
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
  }

  &--muiShadow {
    box-shadow: $mui-shadow;
  }

  &--pointer {
    cursor: pointer;
  }

  &--pointerEventsNone {
    pointer-events: none;
  }

  &--ellipsis {
    @include truncate();
  }

  &--disabled {
    @include disabled();
  }

  &--transitionAll {
    transition: all $duration;

    &:hover {
      transition: all $duration;
    }
  }

  &--noWrap {
    white-space: nowrap;
  }
}
