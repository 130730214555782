@import 'constants';

$module: 'GeosuggestInput';

.#{$module} {
  &.geosuggest {
    box-sizing: border-box;
    font-size: $fs18;
    height: 100%;
    margin-top: 14px;
    position: relative;
    text-align: left;
  }

  .geosuggest {
    &__input-wrapper {
      height: 100%;
    }

    &__input {
      background-color: transparent;
      border: none;
      color: $navy;
      cursor: inherit;
      font-family: inherit;
      font-size: $fs18;
      font-stretch: inherit;
      font-style: inherit;
      font-variant: inherit;
      font-weight: inherit;
      height: 100%;
      outline: none;
      padding: 0;
      position: relative;
      width: 100%;
    }

    &__input:focus {
      border: none;
      box-shadow: none;
    }

    &__suggests {
      background: $white;
      border: 1px solid $purple;
      left: 0;
      list-style: none;
      margin-top: -22px;
      max-height: 25em;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;
      position: absolute;
      right: 0;
      top: 100%;
      transition: max-height $duration, border $duration;
      z-index: 5;
    }

    &__suggests--hidden {
      border-width: 0;
      // In some cases we set the max-height for the not-hidden state via props. It was overriding
      // this max-height for when it's hidden, so the drop-down was failing to hide. The result is
      // the use of !important below.
      max-height: 0 !important;
      overflow: hidden;
    }

    &__item {
      cursor: pointer;
      font-size: $fs16;
      padding: $xs;
    }

    &__item:hover,
    &__item:focus {
      background: lighten($purple, 40%);
    }

    &__item--active {
      background: $purple;
      color: $white;
    }

    &__item--active:hover,
    &__item--active:focus {
      background: $purple;
    }

    &__item__matched-text {
      font-weight: $fw700;
    }
  }
}
