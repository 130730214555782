@import 'constants';
@import 'mixins';

$module: 'FDBDatePicker';

.#{$module} {
  height: 40px;
  // Hide the built in Mui Input
  .FDBDatePicker__hidden {
    top: -40px;
    visibility: hidden;
  }

  .FDBTextInput {
    // Hide the clear-button by default
    .MuiInputAdornment-positionEnd .FDBIconButton {
      margin-right: -10px;
      visibility: hidden;
    }

    // Hide blinking cursor when there is a date or error
    &.hideCursor .MuiOutlinedInput-input {
      caret-color: transparent;
      cursor: default;
    }

    // Show the clear-button when input is hovered or focused
    &:hover,
    &:focus-within {
      .MuiInputAdornment-positionEnd .FDBIconButton {
        visibility: visible;
      }
    }
  }

  // Also show the clear-button when it is hovered or focused itself
  .FDBIconButton:hover {
    visibility: visible;
  }

  .FDBIconButton.FDBIconButton:focus {
    visibility: visible;
    outline-offset: -2px;
  }

  // Calendar Paper styles
  .MuiPaper-root.MuiPickersPopper-paper {
    border: 1px solid var(--mono300);
    border-radius: 4px;
    box-shadow: $shadow-z2;
    margin-top: 4px;

    .MuiDateCalendar-root {
      width: 330px;
      height: unset;
      max-height: unset;
      padding-bottom: 12px;

      .MuiButtonBase-root .MuiTouchRipple-root {
        display: none;
      }

      .MuiPickersCalendarHeader-labelContainer {
        padding: 3px;
      }

      // Styling the prev/next month icons to be like iconbuttons
      .MuiPickersArrowSwitcher-root {
        margin-right: -2px;

        .MuiPickersArrowSwitcher-button {
          &[aria-label='Previous month'],
          &[aria-label='Next month'] {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            padding: 0;

            &:focus-visible {
              background-color: var(--primary100);
              color: var(--primary700);
              outline: solid 2px $teal300;
              outline-offset: 1px;

              &:active {
                background-color: var(--primary300);
                color: var(--primary900);
              }
            }
          }
        }

        .FDBIcon--ChevronLeft,
        .FDBIcon--ChevronRight {
          padding: 8px;
          border-radius: 50%;
          color: var(--primary500);

          &:hover {
            color: var(--primary700);
            background-color: var(--primary100);
          }

          &:active {
            color: var(--primary900);
            background-color: var(--primary300);
          }
        }
      }

      // Show years button style
      .FDBIconButton.ShowYears .FDBIcon {
        width: 10px !important;
      }

      .MuiPickersCalendarHeader-switchViewButton {
        color: var(--primary500);
        width: 32px;
        height: 32px;

        &:hover {
          border: none;
          background-color: var(--primary100);
          color: var(--primary700);
        }

        &:active {
          border: none;
          background-color: var(--primary300);
          color: var(--primary900);
        }
      }

      .MuiPickersCalendarHeader-switchViewButton:focus-visible {
        border: none;
        background-color: var(--primary100);
        color: var(--primary700);
        outline: solid 2px $teal300;
        outline-offset: 1px;
      }

      .MuiButtonBase-root.MuiPickersArrowSwitcher-button:hover {
        border: none;
        background-color: unset;
      }

      // Calendar header ie: December 2023
      .MuiPickersCalendarHeader-label {
        color: var(--mono900);
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
      }

      .MuiDayCalendar-root .MuiDayCalendar-header .MuiDayCalendar-weekDayLabel {
        width: 38px;
        height: 34px;

        &.MuiTypography-root.MuiTypography-caption {
          margin: 2px;
          font-size: 14px;
        }
      }

      & .MuiDayCalendar-monthContainer .MuiButtonBase-root {
        margin: 0 2px;
        border-radius: 50%;
      }

      // Individual calendar date circles
      .MuiPickersDay-root {
        color: var(--mono900);
        font-size: 16px;
        width: 38px;
        height: 38px;

        &:hover,
        &:focus {
          background-color: var(--primary100);
          color: var(--primary700);
          border: 1px solid;
          border-color: var(--primary700);
        }

        &:active {
          background-color: var(--primary300);
          color: var(--primary900);
          border-color: var(--primary900);
        }

        &.Mui-selected {
          color: var(--mono0);
          background-color: var(--primary900);
          font-weight: 700;

          &:hover,
          &:focus {
            color: var(--mono0);
            background-color: var(--primary700);
          }
        }

        &.MuiPickersDay-today {
          border-color: var(--primary500);
          color: var(--primary500);
          font-weight: 700;
          box-shadow: inset 0 0 0 0.5px var(--primary500);
          background-color: var(--mono0);

          &:hover,
          &:focus {
            background-color: var(--primary100);
            color: var(--primary900);
            border-color: var(--primary900);
          }

          &.Mui-selected {
            color: var(--mono0);
            background-color: var(--primary900);
            font-weight: 700;

            &:hover,
            &:focus {
              color: var(--mono0);
              background-color: var(--primary700);
            }

            &.Mui-disabled {
              opacity: 1;
              color: var(--mono500);
            }
          }
        }
      }

      // Individual year pills
      .MuiYearCalendar-root {
        max-height: 274px;

        .MuiPickersYear-root {
          .MuiPickersYear-yearButton {
            width: 78px;
            border: 1px solid var(--mono0);
            border-radius: 9999px;
            font-size: unset;
            line-height: unset;

            &:hover,
            &:focus {
              color: var(--primary700);
              border-color: var(--primary700);
              background-color: var(--primary100);
            }

            &:active {
              background-color: var(--primary300);
              color: var(--primary900);
              border-color: var(--primary900);
            }

            &.Mui-selected {
              border-color: var(--primary900);
              background-color: var(--primary900);
              font-weight: 700;

              &:hover,
              &:focus {
                color: var(--mono0);
                background-color: var(--primary700);
              }
            }
          }
        }
      }
    }
  }
  // styles needed for admin
  &.FDBDatePicker--isActiveAdmin .MuiPaper-root.MuiPickersPopper-paper {
    & button {
      background-image: unset;
      box-shadow: none;
      text-shadow: none;
      border-radius: 50%;

      &:hover {
        background-image: unset;
      }
    }

    & .MuiButtonBase-root.Mui-disabled.Mui-disabled {
      color: var(--mono500);

      &.MuiPickersDay-today {
        border-color: var(--mono500);
      }
    }

    & .Mui-disabled .FDBIcon {
      color: var(--mono500);
    }

    & .MuiPickersArrowSwitcher-root button {
      box-sizing: unset;
    }
  }
}
