@keyframes appearsFromTop {
  from {
    top: -120px;
    opacity: 0;
    position: relative;
  }

  to {
    opacity: 1;
    top: 0;
    position: relative;
  }
}

@keyframes appearsFromBottom {
  from {
    opacity: 0;
    position: relative;
    top: 120px;
  }

  to {
    position: relative;
    top: 0;
    opacity: 1;
  }
}

@keyframes disappearsToTop {
  from {
    position: relative;
    margin-bottom: 0;
    opacity: 1;
  }

  to {
    position: relative;
    margin-bottom: 240px;
    opacity: 0;
  }
}

@keyframes disappearsToTopSmall {
  from {
    position: relative;
    opacity: 1;
  }

  to {
    position: relative;
    opacity: 0;
  }
}

@keyframes subcontentDisappearsToTop {
  from {
    position: relative;
    margin-bottom: 0;
    opacity: 1;
  }

  to {
    position: relative;
    margin-bottom: 240px;
    opacity: 0;
  }
}

@keyframes appearsInPlaceIncrease {
  from {
    position: relative;
    transform: scale(0);
    opacity: 0;
  }

  to {
    position: relative;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes appearsInPlaceFadeIn {
  0% {
    position: relative;
    visibility: hidden;
    opacity: 0;
  }

  100% {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
}

.content-appears-top-bottom {
  opacity: 0;
  position: relative;
  animation-name: appearsFromTop, disappearsToTop;
  animation-duration: 400ms, 800ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 450ms, 5000ms;
}

.content-fade-in-and-out {
  --animation-disappear-delay: 3800ms;

  opacity: 0;
  position: relative;
  animation-name: appearsFromBottom, disappearsToTop;
  animation-duration: 600ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 200ms, var(--animation-disappear-delay);
}

.content-with-subcontent-fade-in-and-out {
  opacity: 0;
  position: relative;
  animation-name: appearsFromBottom, disappearsToTopSmall;
  animation-duration: 600ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 200ms, 3800ms;
}

.subcontent-fade-in-and-out {
  opacity: 0;
  position: relative;
  animation-name: appearsFromBottom, subcontentDisappearsToTop;
  animation-duration: 600ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 1200ms, 3800ms;
}

.how-you-do-it-appears-in-place-increase {
  --element-display-delay-ms: 1200ms;

  opacity: 0;
  position: relative;
  animation-name: appearsInPlaceIncrease;
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: var(--element-display-delay-ms);
}

.how-you-do-it-appears-in-place-fade-in {
  --element-display-delay-ms: 1500ms;

  opacity: 0;
  position: relative;
  animation-name: appearsInPlaceFadeIn;
  animation-duration: 1000ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: var(--element-display-delay-ms);
}

.how-you-do-it-content-fade-out {
  opacity: 1;
  position: relative;
  animation-name: disappearsToTop;
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 5000ms;
}

.john-video-fade-out {
  opacity: 1;
  position: relative;
  animation-name: disappearsToTop;
  animation-duration: 600ms;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-delay: 150ms;
}
