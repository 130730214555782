@import 'constants';
@import 'mixins';

$module: 'RadioGroup';

.#{$module} {
  padding-left: 10px;

  &__radioLabel {
    padding-bottom: 10px;

    .MuiTypography-body1 {
      font-size: inherit;
    }
  }

  &--small &__radio {
    .MuiIconButton-label {
      transform: scale(0.8);
      width: 18px !important;
      height: 18px !important;
    }
  }

  &--small &__radioLabelText {
    font-size: $fs14 !important;
  }
}
