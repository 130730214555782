@import 'constants';

$module: 'Table';

.#{$module} {
  &__header {
    align-items: center;
    border-bottom: $tiny solid $gray-medium;
    border-top: $tiny solid $gray-medium;
    display: flex;

    &--simple {
      background-color: $gray-light;
      border-left: $light-border;
      border-right: $light-border;
      border-top: $light-border;
      box-sizing: border-box;
      font-weight: $fw600;
      text-align: left;
      text-transform: capitalize;
    }

    &--white {
      border-color: $gray-medium;
    }
  }

  &__header-cell {
    align-items: center;
    color: $gray-dark;
    display: flex;
    font-size: $fs14;
    padding-left: $lg;
    text-transform: capitalize;
  }

  &__row {
    border-bottom: $tiny solid $gray-medium;
    color: $navy;
    display: flex;
    font-size: $fs16;
    line-height: 1;
    transition: height $long-duration;
  }

  &--simple {
    .ReactVirtualized__Table__Grid {
      border-bottom: $tiny solid $gray-medium;
      border-left: $tiny solid $gray-medium;
      border-right: $tiny solid $gray-medium;
      margin-right: 1px;
    }
  }

  &--white {
    .ReactVirtualized__Table__Grid {
      background: white;
    }
  }

  // OVERRIDES
  .ReactVirtualized__Grid {
    outline: none;
  }

  .ReactVirtualized__Table__headerColumn:first-of-type {
    margin-left: $md;
    padding-left: 0;
  }

  .ReactVirtualized__Table__rowColumn {
    padding-left: $lg;
  }

  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 0;
    padding-left: 0;
  }

  &--pad-first-column {
    .ReactVirtualized__Table__rowColumn:first-of-type {
      margin-left: $lg;
      padding-left: 0;
    }
  }
}
