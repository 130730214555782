@import 'constants';

$module: 'NavItem';

.#{$module} {
  position: relative;

  &__inner {
    color: $violet-light;
    position: relative;
    transition: color $duration;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: $white;
    }

    &--active {
      color: $white;
    }

    &--whiteText {
      color: $white;
    }

    &--inverse {
      color: $purple;

      &:hover,
      &:focus {
        color: $purple-dark;
      }

      .#{$module}--active {
        color: $purple-dark;
      }

      .#{$module}--white {
        color: $purple-dark;
      }
    }
  }

  &__notification-indicator {
    background: $red;
    border-radius: 15px;
    top: 10px;
    display: inline-block;
    height: 4px;
    position: absolute;
    right: -6px;
    width: 4px;

    &--mobile {
      position: relative;
      right: 0;
      top: -5px;
    }
  }

  &__red-pill {
    background: $red;
    border-radius: $border-radius;
    bottom: $xxs;
    color: $white;
    font-size: $xs;
    height: $xs;
    left: 50%;
    padding-bottom: $tiny;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 35px;

    &--mobile {
      bottom: 12px;
      left: auto;
      padding-bottom: $xxxs;
      right: 0;
    }
  }

  &__caret {
    bottom: -6px;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity $duration;

    &--active {
      opacity: 1;
    }
  }

  &--menu {
    .#{$module}__menu {
      display: none;
      opacity: 0;
      position: absolute;
      top: 100%;
      transition: opacity $duration;

      &:hover {
        display: block;
        opacity: 1;
      }
    }

    &:hover {
      .#{$module}__menu {
        display: block;
        opacity: 1;
      }
    }
  }
}
