@import 'constants';
@import 'mixins';

$module: 'ActionsListDrawer';

.#{$module} {
  &__option:not(:last-child) {
    border-bottom: 1px solid $gray-medium-light;
  }
}
