@import 'constants';
@import 'mixins';

$module: 'UserWebTimeclockNavItem';
$tmask-color: rgba(25, 35, 41, 0.85);

.#{$module} {
  &__custom_overlay {
    &::before {
      z-index: 1000;
      content: '';
      display: block;
      position: absolute;
      height: 48px;
      width: 48px;
      margin-left: -5px;
      border-radius: 50%;
      box-shadow: 0 0 0 9999px $tmask-color;
    }

    @include truncate();
  }
}
