@import 'constants';
@import 'mixins';

$module: 'Box';

.#{$module} {
  transition: opacity $duration;

  &--flex {
    display: flex;
  }

  &--tcenter {
    text-align: center;
  }

  &--tleft {
    text-align: left;
  }

  &--tright {
    text-align: right;
  }

  &--evenwidth > * {
    flex-basis: 0;
    flex-grow: 1;
    margin-right: $xxs;
  }

  &--row {
    display: flex;
    flex-direction: row;
  }

  &--rowreverse {
    display: flex;
    flex-direction: row-reverse;
  }

  &--vtop {
    align-items: start;
  }

  &--vbottom {
    align-items: end;
  }

  &--columnreverse {
    display: flex;
    flex-direction: column-reverse;
  }

  &--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--wrapreverse {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  &--spacebetween {
    display: flex;
    justify-content: space-between;
  }

  &--spacearound,
  &--justify-content-space-around {
    display: flex;
    justify-content: space-around;
  }

  &--spaceevenly,
  &--justify-content-space-evenly {
    display: flex;
    justify-content: space-evenly;
  }

  &--hide {
    display: none;
  }

  &--scrollX {
    overflow-x: auto;
  }

  &--scrollY {
    overflow-y: auto;
  }

  &--relative {
    position: relative;
  }

  &--absolute {
    position: absolute;
  }

  &--absoluteCover {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  &--fixed {
    position: fixed;
  }

  &--sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  &--none {
    display: none;
  }

  &--inline {
    display: inline;
  }

  &--block {
    display: block;
  }

  &--inlineBlock {
    display: inline-block;
  }

  &--borderbox {
    box-sizing: border-box;
  }

  &--shadow {
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
  }

  &--muiShadow {
    box-shadow: $mui-shadow;
  }

  &--pointer {
    cursor: pointer;
  }

  &--pointerEventsNone {
    pointer-events: none;
  }

  &--b {
    border: 1px solid $gray-medium-light;
  }

  &--bv,
  &--bb {
    border-bottom: 1px solid $gray-medium-light;
  }

  &--bv,
  &--bt {
    border-top: 1px solid $gray-medium-light;
  }

  &--bh,
  &--bl {
    border-left: 1px solid $gray-medium-light;
  }

  &--bh,
  &--br {
    border-right: 1px solid $gray-medium-light;
  }

  &--ellipsis {
    @include truncate();
  }

  &--disabled {
    @include disabled();
  }

  &--transitionAll {
    transition: all $duration;

    &:hover {
      transition: all $duration;
    }
  }

  &--noWrap {
    white-space: nowrap;
  }

  &--vcenter {
    align-items: center;
    display: flex;
  }

  &--hleft {
    display: flex;
    justify-content: flex-start;
  }

  &--hright {
    display: flex;
    justify-content: flex-end;
  }

  &--hcenter {
    display: flex;
    justify-content: center;
  }

  &--column {
    display: flex;
    flex-direction: column;
  }

  &--column.Box--vtop {
    justify-content: start;
    align-items: unset;
  }

  &--column.Box--vbottom {
    justify-content: end;
    align-items: unset;
  }

  &--column.Box--hleft {
    align-items: start;
    justify-content: unset;
  }

  &--column.Box--hright {
    align-items: end;
    justify-content: unset;
  }

  &--column.Box--vcenter {
    justify-content: center;
    align-items: unset;
  }
}
