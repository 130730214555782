@import 'constants';

$module: 'ModalCloseIcon';

.#{$module} {
  transition-duration: $duration;
  line-height: 0;
  cursor: pointer;

  &:hover {
    color: darken($gray-dark, 5%);
    border-color: darken($gray-medium, 5%);
    background-color: darken($white, 5%);
  }
}
