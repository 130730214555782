@import 'constants';
@import 'mixins';

$module: 'Success';

.#{$module} {
  & .welcome-gif {
    width: 338px;

    @include mobile {
      width: 200px;
    }
  }

  & .text-container {
    max-width: 700px;

    @include mobile {
      max-width: 310px;
    }
  }

  & .title {
    @include mobile {
      font-size: 20px;
    }
  }
}
