@import 'constants';
$module: 'AnnounceKitButton';

.#{$module} {
  background-color: rgba(255, 255, 255, 0.15);
  padding: 6px;
  border-radius: 16px;
  border: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
  }

  .announcekit-widget-badge {
    margin-left: 4px;
  }

  .announcekit-widget-badge-hidden {
    max-width: 0;
    margin-left: 0;
  }
}
