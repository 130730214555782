@import 'constants';

$module: 'Popper';

/* source https://codepen.io/weaintplastic/pen/YWOQXB */

.#{$module} {
  #arrow {
    overflow: hidden;
    position: absolute;
    width: 1em;
    height: 0.71em; /* = width / sqrt(2) = (length of the hypotenuse) */
    box-sizing: border-box;

    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
      transform: rotate(45deg);
      background-color: white; /* will need to be overridden for a different color arrow */
    }
  }

  &[x-placement*='bottom'] > #arrow {
    top: 1px;
    left: 0;
    margin-top: -0.71em;
    margin-left: 4px;
    margin-right: 4px;

    &::before {
      transform-origin: 0 100%;
    }
  }

  &[x-placement*='top'] > #arrow {
    bottom: 1px;
    left: 0;
    margin-bottom: -0.71em;
    margin-left: 4px;
    margin-right: 4px;

    &::before {
      transform-origin: 100% 0;
    }
  }

  &[x-placement*='right'] > #arrow {
    left: 0;
    margin-left: -0.71em;
    height: 1em;
    width: 0.71em;
    margin-top: 4px;
    margin-bottom: 4px;

    &::before {
      transform-origin: 100% 100%;
    }
  }

  &[x-placement*='left'] > #arrow {
    right: 0;
    margin-right: -0.71em;
    height: 1em;
    width: 0.71em;
    margin-top: 4px;
    margin-bottom: 4px;

    &::before {
      transform-origin: 0 0;
    }
  }
}
