$module: 'FDBIcon';

.#{$module} {
  aspect-ratio: 1 / 1;

  & svg {
    width: 100%;
    height: auto;
    display: block;
  }

  &--pointer {
    cursor: pointer;
  }
}
