@import 'constants';

$module: 'LeftNavigation';

$navbar-height: 48px;

.#{$module} {
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    // Allows navbar to scroll independently of the rest of the page
    min-height: fit-content;
    // Height adjustment for scroll
    margin-bottom: $navbar-height;
  }

  &__top-menu,
  &__sub-menu,
  &__collapse {
    &:hover,
    &:hover .Text,
    &:hover svg {
      color: $accent900 !important;
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 1.5px;

      g path {
        stroke: $accent900;
      }
    }
  }

  .MuiDivider-root {
    margin-top: 10px;
  }

  .UpgradeButtonNavItem {
    button {
      padding: 0 20px;
    }

    button:hover {
      background-color: darken($purple, 10%);
    }
  }

  img {
    width: 100%;
  }

  &__launch-week-award-badge-animated {
    transition-property: transform;
    transition-duration: 2s;
    animation: grow 0.8s infinite;
  }

  @keyframes grow {
    0% {
      transform: scale(0.92);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.92);
    }
  }

  &__pill {
    .FDBPill {
      color: $mono0 !important;
      background-color: $accent900 !important;
    }
  }
}
