@import 'constants';

$module: 'FDBCardContainer';

.#{$module} {
  background-color: var(--mono0);
  border: 1px solid var(--mono300);

  &:hover {
    background-color: var(--primary100);
    outline: 2px solid var(--primary500);
    border-color: transparent;
  }

  &:active {
    background-color: var(--primary300);
    outline: 2px solid var(--primary500);
    border-color: transparent;
  }

  &--selected {
    outline: 2px solid var(--primary500);
    border-color: transparent;
  }

  &:not([aria-disabled='true']):focus-visible {
    border-color: transparent;
    background-color: var(--primary100);
    box-shadow: inset 0 0 0 2px var(--primary500) !important;
    outline: 2px solid $accent500;
    outline-offset: 2px;
  }

  &[aria-disabled='true']:focus {
    outline: none;
  }

  &--disabled {
    background-color: var(--mono100);
    pointer-events: none;
  }

  &--isAI,
  &--isAIAnimating {
    border-color: transparent;
    outline-color: transparent;
    border-radius: 8px;

    &:not([aria-disabled='true']):focus-visible {
      box-shadow: none !important;
      outline: 0;
    }

    &:hover,
    &:active {
      border-color: transparent;
      outline-color: transparent;
    }
  }
}
