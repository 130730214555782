@import 'constants';
@import 'mixins';

$module: 'Icon';

@mixin size($size) {
  height: $size;
  min-height: $size;
  min-width: $size;
  width: $size;
}

.#{$module} {
  &--clickable {
    color: $blue;

    &:hover {
      color: $blue-light;
      cursor: pointer;
    }
  }

  &--disabled {
    @include disabled();
  }
}
