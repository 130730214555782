@import 'constants';
@import 'mixins';

$module: 'TooltipWrapper';

.Popover.#{$module} {
  display: none !important;

  &--enabled {
    display: flex !important;
  }

  .Popover-body {
    background: $black;
    border-radius: $border-radius;
    color: $white;
    font-size: $fs14;
    max-width: 500px;
    padding: $xxs $xs;
    white-space: pre-line;

    p {
      color: inherit;
    }
  }

  /* left position */
  .Popover-body[style*='order: -1'] + .Popover-tip[width='7'] {
    margin-left: -1px;
  }

  /* right position */
  .Popover-body[style*='order: 1'] + .Popover-tip[width='7'] {
    margin-right: -1px;
  }

  /* below position */
  .Popover-body[style*='order: 1'] + .Popover-tip[width='14'] {
    margin-bottom: -1px;
  }

  /* above position */
  .Popover-body[style*='order: -1'] + .Popover-tip[width='14'] {
    margin-top: -1px;
  }

  &--monoDark {
    .Popover-body {
      background-color: $monoDark;
      color: white;
      font-size: 14px;
      font-weight: 400;
    }

    .Popover-tipShape {
      fill: $monoDark;
    }
  }

  &--navy {
    .Popover-body {
      background-color: $navy;
      color: white;
      padding: 12px;
      font-size: 16px;
      font-weight: 600;
    }

    .Popover-tipShape {
      fill: $navy;
    }
  }

  &--blue {
    .Popover-body {
      background-color: $blue;
      border: 1px solid $navy;
      color: $gray-light;
    }

    .Popover-tipShape {
      fill: $blue;
      stroke: $navy;
      stroke-dasharray: 0, 0, 20;
    }
  }

  &--blue--no-border {
    .Popover-body {
      background-color: $blue;
      border: 1px solid $blue;
      color: $gray-light;
    }

    .Popover-tipShape {
      fill: $blue;
      stroke: $blue;
      stroke-dasharray: 0, 0, 20;
    }
  }

  &--light-blue {
    .Popover-body {
      box-shadow: none;
      background-color: $blue-lightest;
      border: 1px solid $mono300;
      color: $black;
    }

    .Popover-tipShape {
      fill: $blue-lightest;
      stroke: $mono300;
      stroke-dasharray: 0, 0, 20;
    }
  }

  &--gray-light {
    .Popover-body {
      box-shadow: none;
      background-color: $gray-light;
      border: 1px solid #cbcdcf;
      border-radius: 4px;
      color: $navy;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .Popover-tipShape {
      fill: $gray-light;
      stroke: $gray-medium;
      stroke-dasharray: 0, 0, 20;
    }
  }

  &--payroll-admin {
    .Popover-body {
      width: 145px;
      height: 53.4px;
      padding: 11px 9px 17.4px;
      box-shadow: none;
      background-color: $blue-lightest;
      border: 1px solid $secondaryA2;
      color: $black;
      font-weight: 600;
      font-size: 10px;
    }

    .Popover-tipShape {
      fill: $blue-lightest;
      stroke: $secondaryA2;
      stroke-dasharray: 0, 0, 20;
    }
  }

  &--purple {
    .Popover-body {
      background-color: $primary050;
      border-color: $purple;
      color: $purple;
    }

    .Popover-tipShape {
      fill: $primary050;
      stroke: $purple;
      stroke-dasharray: 0, 0, 20;
    }
  }

  &--white,
  &--white-auto-width {
    .Popover-body {
      background-color: white;
      color: $navy;
      border-color: $border-color;
    }

    .Popover-tipShape {
      fill: white;
      stroke: $border-color;
      stroke-dasharray: 0, 0, 20;
    }
  }

  &--white-auto-width {
    .Popover-body {
      max-width: none;
    }
  }

  &--upAndDown {
    animation: upAndDown 1s infinite;
  }

  &--backAndForth {
    animation: backAndForth 1s infinite;
  }

  &--small {
    .Popover-body {
      max-width: 248px;
    }
  }

  &--noPadding {
    .Popover-body {
      padding: 0;
    }
  }
}
