@import 'constants';

$module: 'NavView';

.#{$module} {
  position: relative;

  &__menu-icon {
    color: $violet-light;

    &:hover {
      color: $white;
    }
  }
}

@media print {
  .#{$module} {
    display: none;
  }
}
