@import 'constants';
@import 'mixins';

$module: 'UpgradeWrapper';

.#{$module} {
  position: relative;

  &__child {
    &--disabled {
      @include disabled();

      .MuiCheckbox-root {
        color: $mono500;
      }
    }
  }

  &__barrier {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}
