@font-face {
  font-family: Caveat;
  src: url('fonts/Caveat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Caveat;
  src: url('fonts/Caveat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Caveat;
  src: url('fonts/Caveat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Caveat;
  src: url('fonts/Caveat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: CedarvilleCursive-Regular;
  src: url('fonts/CedarvilleCursive-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src:
    url('fonts/PlusJakartaSans-500.woff2') format('woff2'),
    url('fonts/PlusJakartaSans-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src:
    url('fonts/PlusJakartaSans-700.woff2') format('woff2'),
    url('fonts/PlusJakartaSans-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

.fw400 {
  font-weight: 400;
}
