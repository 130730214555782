$module: 'NotificationsList';

.#{$module} {
  &__fade-exit {
    opacity: 1;
    height: auto;
    max-height: 400px;
  }

  &__fade-exit-active {
    max-height: 0;
    opacity: 0.01;
    transition: max-height 700ms ease-in-out, opacity 400ms ease-in-out;
  }
}
