@import 'constants';

$module: 'AnimatedSuccessIcon';

.#{$module} {
  &__success {
    fill: none;
    stroke: currentColor;
    stroke-width: 2px;
  }

  &__path {
    animation: checkmark 0.25s ease-in-out 0.7s backwards;
    stroke-dasharray: 17px, 17px;
    stroke-dashoffset: 0;
  }

  &__circle {
    animation: checkmark-circle 0.6s ease-in-out forwards;
    stroke-dasharray: 76px, 76px;
    stroke-dashoffset: 0;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  @keyframes checkmark {
    0% {
      stroke-dashoffset: 17px;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes checkmark-circle {
    0% {
      stroke-dashoffset: 76px;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }
}
