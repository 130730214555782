@import '~react-select/dist/react-select.css';
@import '~react-virtualized/styles.css';
@import 'constants';
@import 'mixins';

$height: $xxl - 2;
$narrow-height: $height - $xs -2;
$tag-color: $blue;
$tag-border-color: rgba($tag-color, 0.4);
$tag-background-color: rgba($tag-color, 0.1);
$dark-height: 23px;
$module: 'SelectInput';

// Mostly overrides of default react-select styles
.#{$module} {
  border-radius: $border-radius;
  font-size: $input-font-size;
  line-height: $height;
  position: relative;

  @include tablet {
    font-size: $fs12;
  }

  .Select {
    border-radius: inherit;
  }

  &--bordered {
    .Select-control {
      &:focus-within {
        outline: 2px solid var(--primary700) !important;
        outline-offset: -2px;
      }
    }
  }

  &--is-open {
    z-index: 11 !important;

    .Select-menu-outer {
      z-index: 11 !important;
      overflow-y: initial;
    }
  }

  &--hide-clear {
    .Select-clear-zone {
      display: none;
    }
  }

  &--narrow {
    line-height: $narrow-height;

    .Select-control {
      height: $narrow-height;
    }

    .Select-value {
      line-height: $narrow-height !important;
    }

    .Select-input {
      height: $narrow-height;

      input {
        height: $narrow-height;
      }
    }

    .Select-placeholder {
      line-height: $narrow-height;
    }
  }

  .Select-control {
    border-color: var(--mono400) !important;
    border-radius: inherit;
    box-shadow: none !important;
  }

  &--medium {
    .Select-option {
      font-size: $label-font-size;
    }
  }

  .Select-value {
    align-items: center;
    display: flex;
    line-height: $height !important;
    max-width: calc(100% - #{$xxl}) !important;
  }

  &--hide-arrow {
    .Select-arrow-zone {
      display: none;
    }

    .Select-value {
      max-width: 100% !important;
    }
  }

  .has-value.Select--single .Select-control .Select-value {
    padding-right: 0;
  }

  .Select-value-label {
    color: $neutral700 !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .Select-input {
    height: $height;

    input {
      height: $height;
      padding: 0;
    }
  }

  .Select-placeholder {
    font-size: $placeholder-font-size;
    line-height: $height;
  }

  .Select-clear,
  .Select-arrow {
    color: $gray-dark;
    transition-duration: $duration;
  }

  .Select-clear {
    height: 22px;
  }

  .Select-clear:hover {
    color: $red;
  }

  .Select-menu-outer {
    border-color: $gray-medium;
    box-shadow: none;
    z-index: 1000;
    overflow-y: initial;
  }

  .Select-option {
    background-color: $white;
    color: $black;
    padding: $sm $xs;
    overflow: hidden;
    text-overflow: ellipsis;

    &.is-selected {
      color: var(--primary700);
    }

    &.is-focused {
      background-color: var(--primary100);
    }

    &.is-disabled {
      cursor: default;
      padding-left: 12px;
      font-size: 12px;
      color: $neutral700;
    }
  }

  .Select--multi {
    .Select-value {
      background-color: $tag-background-color;
      border-color: $tag-border-color;
      border-radius: $border-radius;
      color: $tag-color;
      display: inline-block;
      line-height: 22px !important;
      max-width: none !important;
    }

    .Select-value-icon {
      border-bottom-left-radius: $border-radius;
      border-color: $tag-border-color;
      border-top-left-radius: $border-radius;
    }
  }

  &--secondary {
    .Select-control {
      border-color: $blue !important;
    }

    .Select-placeholder {
      color: $blue;
    }

    .Select-arrow {
      border-color: $blue transparent transparent;
    }

    .is-open .Select-arrow,
    &:hover .Select-arrow {
      border-top-color: $blue;
    }

    .is-open .Select-control .Select-arrow {
      border-color: transparent transparent $blue;
    }

    .Select-value-label {
      color: $blue !important;
    }
  }

  &--minimal {
    font-size: $base-font-size;
    font-weight: $fw700;

    .Select-control {
      border: none !important;
      color: inherit;
      font-size: inherit;
    }

    .Select-value-label,
    .Select-value,
    .Select-clear-zone,
    .Select-clear {
      color: inherit !important;
    }

    .Select-arrow {
      border-color: var(--primary700) transparent transparent;
    }

    .is-open .Select-arrow,
    &:hover .Select-arrow {
      border-top-color: var(--primary700);
    }

    .is-open .Select-control .Select-arrow {
      border-color: transparent transparent var(--primary700);
    }
  }

  &--mui,
  &--mui-standalone,
  &--onboarding,
  &--onboarding-red {
    font-size: $fs18;
    height: 100%;
    margin-top: 14px;
    position: relative;
    width: 100%;

    .Select {
      top: 50%;
      transform: translateY(-50%);
    }

    .Select-control {
      border: none !important;
      color: inherit;
      font-size: inherit;
    }

    .Select-input,
    .Select-value {
      padding-left: 0 !important;
    }

    .Select-value-label,
    .Select-value,
    .Select-clear-zone,
    .Select-clear {
      color: inherit !important;
    }

    .Select-arrow {
      border-color: var(--primary700) transparent transparent;
    }

    .is-open .Select-arrow,
    &:hover .Select-arrow {
      border-top-color: var(--primary700);
    }

    .is-open .Select-control .Select-arrow {
      border-color: transparent transparent var(--primary700);
    }

    .is-open .Select-control {
      background: transparent;
    }

    .Select-menu-outer {
      border-color: var(--primary700);
    }
  }

  &--mui-standalone {
    .Select {
      border-bottom: 1px solid var(--primary700);
      border-radius: 0;
    }
  }

  &--onboarding,
  &--onboarding-red {
    .Select {
      border-bottom: 1px solid $gray-dark;
      border-radius: 0;
    }

    .Select-placeholder {
      padding-left: 0;
      font-size: 18px;
      color: $gray-dark;
    }
  }

  &--onboarding-red {
    .Select {
      border-bottom: 2px solid $red;
    }
  }

  &--mui-dark,
  &--mui-dark-bold {
    font-size: $fs16;
    width: 100%;
    height: 100%;
    line-height: $dark-height;
    // Custom to match MUI TextField's built-in styling
    border-radius: 0;

    .Select {
      line-height: $dark-height;
    }

    .Select-control {
      border: none !important;
      color: inherit;
      font-size: inherit;
      line-height: $dark-height;
      height: auto;
    }

    .Select-value {
      line-height: $dark-height !important;
    }

    .Select-input {
      height: $dark-height;

      input {
        height: $dark-height;
      }
    }

    .Select-placeholder {
      line-height: $dark-height;
      padding-left: 0;
      font-size: $fs16;
    }

    .Select-input,
    .Select-value {
      padding-left: 0 !important;
      line-height: $dark-height;
      height: $dark-height;
    }

    .Select-value-label,
    .Select-value,
    .Select-clear-zone,
    .Select-clear {
      color: inherit !important;
    }

    .Select-arrow {
      border-color: $navy transparent transparent;
    }

    .Select-arrow-zone {
      top: 0;
    }

    .is-open .Select-arrow,
    &:hover .Select-arrow {
      border-top-color: $navy;
    }

    .is-open .Select-control .Select-arrow {
      border-color: transparent transparent $navy;
    }

    .is-open .Select-control {
      background: transparent;
    }

    .Select-menu-outer {
      border-color: transparent;
      border: 1px solid $border-color;
    }
  }

  &--mui-dark-bold {
    margin-top: 0;

    .Select {
      top: 0;
      transform: unset;
    }

    .Select-value {
      max-width: calc(100% - 20px) !important;
    }

    .Select-value-label {
      font-weight: 600;
      font-size: 18px;
    }
  }

  &--errorborder {
    border: 1px solid $red !important;
  }
}
