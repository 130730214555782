@import 'constants';
@import 'lib/fe-design-base/styles/ai';

$module: 'FDBBox';

.#{$module} {
  border-style: solid;
  border-width: 0;

  &--flex {
    display: flex;
  }

  &--tcenter {
    text-align: center;
  }

  &--tleft {
    text-align: left;
  }

  &--tright {
    text-align: right;
  }

  &--row {
    display: flex;
    flex-direction: row;
  }

  &--rowreverse {
    display: flex;
    flex-direction: row-reverse;
  }

  &--vtop {
    align-items: start;
  }

  &--vbottom {
    align-items: end;
  }

  &--columnreverse {
    display: flex;
    flex-direction: column-reverse;
  }

  &--wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &--wrapreverse {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  &--hide {
    display: none;
  }

  &--scrollX {
    overflow-x: auto;
  }

  &--scrollY {
    overflow-y: auto;
  }

  &--relative {
    position: relative;
  }

  &--absolute {
    position: absolute;
  }

  &--absoluteCover {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  &--fixed {
    position: fixed;
  }

  &--sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  &--none {
    display: none;
  }

  &--inline {
    display: inline;
  }

  &--block {
    display: block;
  }

  &--inlineBlock {
    display: inline-block;
  }

  &--grid {
    display: grid;
  }

  &--borderbox {
    box-sizing: border-box;
  }

  &--pointer {
    cursor: pointer;
  }

  &--pointerEventsNone {
    pointer-events: none;
  }

  &--ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--noWrap {
    white-space: nowrap;
  }

  &--vcenter {
    align-items: center;
    display: flex;
  }

  &--hleft {
    display: flex;
    justify-content: flex-start;
  }

  &--hright {
    display: flex;
    justify-content: flex-end;
  }

  &--hcenter {
    display: flex;
    justify-content: center;
  }

  &--column {
    display: flex;
    flex-direction: column;
  }

  &--column.FDBBox--vtop {
    justify-content: start;
    align-items: unset;
  }

  &--column.FDBBox--vbottom {
    justify-content: end;
    align-items: unset;
  }

  &--column.FDBBox--hleft {
    align-items: start;
    justify-content: unset;
  }

  &--column.FDBBox--hright {
    align-items: end;
    justify-content: unset;
  }

  &--column.FDBBox--vcenter {
    justify-content: center;
    align-items: unset;
  }

  &--column.FDBBox--hcenter {
    align-items: center;
    justify-content: unset;
  }

  &--alignItemsStart {
    align-items: flex-start;
    display: flex;
  }

  &--alignItemsEnd {
    align-items: flex-end;
    display: flex;
  }

  &--alignItemsCenter {
    align-items: center;
    display: flex;
  }

  &--baseline,
  &--alignItemsBaseline {
    align-items: baseline;
    display: flex;
  }

  &--stretch,
  &--alignItemsStretch {
    align-items: stretch;
    display: flex;
  }

  &--alignSelfStart {
    align-self: start;
  }

  &--alignSelfCenter {
    align-self: center;
  }

  &--alignSelfEnd {
    align-self: end;
  }

  &--justifyContentStart {
    display: flex;
    justify-content: flex-start;
  }

  &--justifyContentEnd {
    display: flex;
    justify-content: flex-end;
  }

  &--justifyContentCenter {
    display: flex;
    justify-content: center;
  }

  &--spacebetween,
  &--justifyContentSpaceBetween {
    display: flex;
    justify-content: space-between;
  }

  &--spacearound,
  &--justifyContentSpaceAround {
    display: flex;
    justify-content: space-around;
  }

  &--spaceevenly,
  &--justifyContentSpaceEvenly {
    display: flex;
    justify-content: space-evenly;
  }

  &--bradiuss,
  &--bradiuss.#{$module}--isAI::after,
  &--bradiuss.#{$module}--isAIAnimating::after {
    border-radius: 4px;
  }

  &--bradiuss.#{$module}--isAI::before,
  &--bradiuss.#{$module}--isAIAnimating::before {
    border-radius: 2px;
  }

  &--bradiusm,
  &--bradiusm.#{$module}--isAI::after,
  &--bradiusm.#{$module}--isAIAnimating::after {
    border-radius: 8px;
  }

  &--bradiusm.#{$module}--isAI::before,
  &--bradiusm.#{$module}--isAIAnimating::before {
    border-radius: 6px;
  }

  &--bradiusl,
  &--bradiusl.#{$module}--isAI::after,
  &--bradiusl.#{$module}--isAIAnimating::after {
    border-radius: 16px;
  }

  &--bradiusl.#{$module}--isAI::before,
  &--bradiusl.#{$module}--isAIAnimating::before {
    border-radius: 14px;
  }

  &--bradiusfull,
  &--bradiusfull.#{$module}--isAI::after,
  &--bradiusfull.#{$module}--isAIAnimating::after,
  &--bradiusfull.#{$module}--isAI::before,
  &--bradiusfull.#{$module}--isAIAnimating::before {
    border-radius: 9999px;
    //provide a sufficiently huge value in pixels or any other absolute measurement unit.
    // If the value exceeds half of the shortest side's length, the browser will use the minimum as its border-radius in both directions,
    // producing a perfect pill shape on rectangular elements.

    // https://stackoverflow.com/questions/31617136/avoid-elliptical-shape-in-css-border-radius
  }

  &--isAI,
  &--isAIAnimating {
    position: relative;
    border-color: transparent !important;
    outline-color: transparent !important;
    z-index: 0;
  }

  &--isAI::after {
    @include ai-border-after;
  }

  &--isAI::before,
  &--isAIAnimating::before {
    @include ai-border-before;

    background-color: var(--ai-background-color);
  }

  &--isAIAnimating::after {
    @include ai-animate-border;
  }
}
