@import 'constants';

$module: 'LaunchWeekSharingDialog';

.#{$module} {
  &__default {
    .MuiPaper-root {
      border-radius: 0 !important;
      background-size: 100% 200%;
      background-position: 100% 200%;
      background-image: linear-gradient(to top, $purple900 50%, $mono100 50%);
      animation: slideUp 400ms reverse linear;
    }
  }

  @keyframes slideUp {
    from {
      background-position: 100% 200%;
    }

    to {
      background-position: 0% 100%;
    }
  }

  // https://stackoverflow.com/questions/30855985/pure-css-animation-visibility-with-delay
  @keyframes changeImage {
    99% {
      visibility: hidden;
    }

    100% {
      visibility: visible;
    }
  }

  &__image-sequence {
    position: absolute;
    top: 38%;
    left: 38%;
    width: 25%;
    visibility: hidden;
    animation-name: changeImage;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 400ms;
  }

  .FDBIconButton {
    z-index: 1;
  }

  .MuiPaper-root {
    @keyframes animatedBackground {
      from {
        background-size: 50% 50%;
      }

      to {
        background-size: 145% 145%;
      }
    }

    border-radius: 0 !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('assets/launch_week_explosion.svg');
    animation: animatedBackground 500ms linear forwards;

    @keyframes appearsFromBottom {
      from {
        opacity: 0;
        position: relative;
        top: 120px;
      }

      to {
        position: relative;
        top: 0;
        opacity: 1;
      }
    }

    .appearsFromBottom {
      opacity: 0;
      position: relative;
      animation-name: appearsFromBottom;
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-delay: 1500ms;
    }

    @keyframes appearsInPlace {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes appearsInPlaceIncrease {
      from {
        opacity: 0;
        transform: scale(0);
      }

      to {
        opacity: 1;
        transform: scale(1.2);
      }
    }

    @keyframes appearsInPlaceDecrease {
      from {
        transform: scale(1.2);
      }

      to {
        transform: scale(1);
      }
    }

    .appears-in-place-increase-decrease {
      opacity: 0;
      scale: 1;
      position: relative;
      animation-name: appearsInPlaceIncrease, appearsInPlaceDecrease;
      animation-duration: 800ms, 800ms;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-delay: 500ms, 1400ms;
    }

    .appears-in-place {
      opacity: 0;
      animation-name: appearsInPlace;
      animation-duration: 800ms;
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-delay: 800ms;
    }
  }
}
