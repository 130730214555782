@import 'constants';

$module: 'StickyBanner';

.#{$module} {
  &--success {
    background: lighten($green, 40%);
    color: $green;
  }

  &--notice {
    background: lighten($yellow-dark, 35%);
    color: $yellow-dark;
  }

  &--error {
    background: lighten($red, 40%);
    color: $red;
  }

  &--blue {
    background-color: $blue;
    color: $white;
  }
}
