@import 'constants';

$module: 'TrialAwarenessUpgradeButton';

.#{$module} {
  #TrialAwarenessUpgradeButton__manage_plan {
    background-color: $teal100;
    border-radius: 5px;
    color: $teal500;
    padding: 2px 10px;
  }

  &__clickable-link {
    &:hover {
      cursor: pointer;
    }
  }
}
