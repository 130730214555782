.man {
  margin: 0;
}

.mtn {
  margin-top: 0;
}

.mrn {
  margin-right: 0;
}

.mbn {
  margin-bottom: 0;
}

.mvn {
  margin-bottom: 0;
  margin-top: 0;
}

.mhn {
  margin-left: 0;
  margin-right: 0;
}

.mln {
  margin-left: 0;
}

.pan {
  padding: 0;
}

.pvn {
  padding-bottom: 0;
  padding-top: 0;
}

.phn {
  padding-left: 0;
  padding-right: 0;
}

.ptn {
  padding-top: 0;
}

.prn {
  padding-right: 0;
}

.pbn {
  padding-bottom: 0;
}

.pln {
  padding-left: 0;
}

.maxxxxl {
  margin: 75px;
}

.mvxxxxl {
  margin-bottom: 75px;
  margin-top: 75px;
}

.mhxxxxl {
  margin-left: 75px;
  margin-right: 75px;
}

.mtxxxxl {
  margin-top: 75px;
}

.mrxxxxl {
  margin-right: 75px;
}

.mbxxxxl {
  margin-bottom: 75px;
}

.mlxxxxl {
  margin-left: 75px;
}

.paxxxxl {
  padding: 75px;
}

.pvxxxxl {
  padding-bottom: 75px;
  padding-top: 75px;
}

.phxxxxl {
  padding-left: 75px;
  padding-right: 75px;
}

.ptxxxxl {
  padding-top: 75px;
}

.prxxxxl {
  padding-right: 75px;
}

.pbxxxxl {
  padding-bottom: 75px;
}

.plxxxxl {
  padding-left: 75px;
}

.maxxxl {
  margin: 60px;
}

.mvxxxl {
  margin-bottom: 60px;
  margin-top: 60px;
}

.mhxxxl {
  margin-left: 60px;
  margin-right: 60px;
}

.mtxxxl {
  margin-top: 60px;
}

.mrxxxl {
  margin-right: 60px;
}

.mbxxxl {
  margin-bottom: 60px;
}

.mlxxxl {
  margin-left: 60px;
}

.paxxxl {
  padding: 60px;
}

.pvxxxl {
  padding-bottom: 60px;
  padding-top: 60px;
}

.phxxxl {
  padding-left: 60px;
  padding-right: 60px;
}

.ptxxxl {
  padding-top: 60px;
}

.prxxxl {
  padding-right: 60px;
}

.pbxxxl {
  padding-bottom: 60px;
}

.plxxxl {
  padding-left: 60px;
}

.maxxl {
  margin: 40px;
}

.mvxxl {
  margin-bottom: 40px;
  margin-top: 40px;
}

.mhxxl {
  margin-left: 40px;
  margin-right: 40px;
}

.mtxxl {
  margin-top: 40px;
}

.mrxxl {
  margin-right: 40px;
}

.mbxxl {
  margin-bottom: 40px;
}

.mlxxl {
  margin-left: 40px;
}

.paxxl {
  padding: 40px;
}

.pvxxl {
  padding-bottom: 40px;
  padding-top: 40px;
}

.phxxl {
  padding-left: 40px;
  padding-right: 40px;
}

.ptxxl {
  padding-top: 40px;
}

.prxxl {
  padding-right: 40px;
}

.pbxxl {
  padding-bottom: 40px;
}

.plxxl {
  padding-left: 40px;
}

.maxl {
  margin: 30px;
}

.mvxl {
  margin-bottom: 30px;
  margin-top: 30px;
}

.mhxl {
  margin-left: 30px;
  margin-right: 30px;
}

.mtxl {
  margin-top: 30px;
}

.mrxl {
  margin-right: 30px;
}

.mbxl {
  margin-bottom: 30px;
}

.mlxl {
  margin-left: 30px;
}

.paxl {
  padding: 30px;
}

.pvxl {
  padding-bottom: 30px;
  padding-top: 30px;
}

.phxl {
  padding-left: 30px;
  padding-right: 30px;
}

.ptxl {
  padding-top: 30px;
}

.prxl {
  padding-right: 30px;
}

.pbxl {
  padding-bottom: 30px;
}

.plxl {
  padding-left: 30px;
}

.mal {
  margin: 25px;
}

.mvl {
  margin-bottom: 25px;
  margin-top: 25px;
}

.mhl {
  margin-left: 25px;
  margin-right: 25px;
}

.mtl {
  margin-top: 25px;
}

.mrl {
  margin-right: 25px;
}

.mbl {
  margin-bottom: 25px;
}

.mll {
  margin-left: 25px;
}

.pal {
  padding: 25px;
}

.pvl {
  padding-bottom: 25px;
  padding-top: 25px;
}

.phl {
  padding-left: 25px;
  padding-right: 25px;
}

.ptl {
  padding-top: 25px;
}

.prl {
  padding-right: 25px;
}

.pbl {
  padding-bottom: 25px;
}

.pll {
  padding-left: 25px;
}

.mam {
  margin: 20px;
}

.mvm {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mhm {
  margin-left: 20px;
  margin-right: 20px;
}

.mtm {
  margin-top: 20px;
}

.mrm {
  margin-right: 20px;
}

.mbm {
  margin-bottom: 20px;
}

.mlm {
  margin-left: 20px;
}

.pam {
  padding: 20px;
}

.pvm {
  padding-bottom: 20px;
  padding-top: 20px;
}

.phm {
  padding-left: 20px;
  padding-right: 20px;
}

.ptm {
  padding-top: 20px;
}

.prm {
  padding-right: 20px;
}

.pbm {
  padding-bottom: 20px;
}

.plm {
  padding-left: 20px;
}

.mas {
  margin: 15px;
}

.mvs {
  margin-bottom: 15px;
  margin-top: 15px;
}

.mhs {
  margin-left: 15px;
  margin-right: 15px;
}

.mts {
  margin-top: 15px;
}

.mrs {
  margin-right: 15px;
}

.mbs {
  margin-bottom: 15px;
}

.mls {
  margin-left: 15px;
}

.pas {
  padding: 15px;
}

.pvs {
  padding-bottom: 15px;
  padding-top: 15px;
}

.phs {
  padding-left: 15px;
  padding-right: 15px;
}

.pts {
  padding-top: 15px;
}

.prs {
  padding-right: 15px;
}

.pbs {
  padding-bottom: 15px;
}

.pls {
  padding-left: 15px;
}

.maxs {
  margin: 10px;
}

.mvxs {
  margin-bottom: 10px;
  margin-top: 10px;
}

.mhxs {
  margin-left: 10px;
  margin-right: 10px;
}

.mtxs {
  margin-top: 10px;
}

.mtxs-negative {
  margin-top: -10px;
}

.mrxs {
  margin-right: 10px;
}

.mbxs {
  margin-bottom: 10px;
}

.mlxs {
  margin-left: 10px;
}

.paxs {
  padding: 10px;
}

.pvxs {
  padding-bottom: 10px;
  padding-top: 10px;
}

.phxs {
  padding-left: 10px;
  padding-right: 10px;
}

.ptxs {
  padding-top: 10px;
}

.prxs {
  padding-right: 10px;
}

.pbxs {
  padding-bottom: 10px;
}

.plxs {
  padding-left: 10px;
}

.maxxs {
  margin: 7px;
}

.mvxxs {
  margin-bottom: 7px;
  margin-top: 7px;
}

.mhxxs {
  margin-left: 7px;
  margin-right: 7px;
}

.mtxxs {
  margin-top: 7px;
}

.mrxxs {
  margin-right: 7px;
}

.mbxxs {
  margin-bottom: 7px;
}

.mlxxs {
  margin-left: 7px;
}

.paxxs {
  padding: 7px;
}

.pvxxs {
  padding-bottom: 7px;
  padding-top: 7px;
}

.phxxs {
  padding-left: 7px;
  padding-right: 7px;
}

.ptxxs {
  padding-top: 7px;
}

.prxxs {
  padding-right: 7px;
}

.pbxxs {
  padding-bottom: 7px;
}

.plxxs {
  padding-left: 7px;
}

.maxxxs {
  margin: 3px;
}

.mvxxxs {
  margin-bottom: 3px;
  margin-top: 3px;
}

.mhxxxs {
  margin-left: 3px;
  margin-right: 3px;
}

.mtxxxs {
  margin-top: 3px;
}

.mrxxxs {
  margin-right: 3px;
}

.mbxxxs {
  margin-bottom: 3px;
}

.mlxxxs {
  margin-left: 3px;
}

.matiny {
  margin: 1px;
}

.mvtiny {
  margin-bottom: 1px;
  margin-top: 1px;
}

.mhtiny {
  margin-left: 1px;
  margin-right: 1px;
}

.mttiny {
  margin-top: 1px;
}

.mrtiny {
  margin-right: 1px;
}

.mbtiny {
  margin-bottom: 1px;
}

.mltiny {
  margin-left: 1px;
}

.paxxxs {
  padding: 3px;
}

.pvxxxs {
  padding-bottom: 3px;
  padding-top: 3px;
}

.phxxxs {
  padding-left: 3px;
  padding-right: 3px;
}

.ptxxxs {
  padding-top: 3px;
}

.prxxxs {
  padding-right: 3px;
}

.pbxxxs {
  padding-bottom: 3px;
}

.plxxxs {
  padding-left: 3px;
}

.mta {
  margin-top: auto;
}

.mba {
  margin-bottom: auto;
}

.mla {
  margin-left: auto;
}

.mra {
  margin-right: auto;
}

.mva {
  margin-bottom: auto;
  margin-top: auto;
}

.mha {
  margin-left: auto;
  margin-right: auto;
}

// Base4 spacing
@mixin spacing-classes($num) {
  .ma#{$num} {
    margin: #{$num}px;
  }

  .mv#{$num} {
    margin-bottom: #{$num}px;
    margin-top: #{$num}px;
  }

  .mh#{$num} {
    margin-left: #{$num}px;
    margin-right: #{$num}px;
  }

  .mt#{$num} {
    margin-top: #{$num}px;
  }

  .mr#{$num} {
    margin-right: #{$num}px;
  }

  .mb#{$num} {
    margin-bottom: #{$num}px;
  }

  .ml#{$num} {
    margin-left: #{$num}px;
  }

  .pa#{$num} {
    padding: #{$num}px;
  }

  .pv#{$num} {
    padding-bottom: #{$num}px;
    padding-top: #{$num}px;
  }

  .ph#{$num} {
    padding-left: #{$num}px;
    padding-right: #{$num}px;
  }

  .pt#{$num} {
    padding-top: #{$num}px;
  }

  .pr#{$num} {
    padding-right: #{$num}px;
  }

  .pb#{$num} {
    padding-bottom: #{$num}px;
  }

  .pl#{$num} {
    padding-left: #{$num}px;
  }
}

$base-4-spaces: 0 1 2 4 8 12 16 20 24 32 36 48 64;

@each $space-num in $base-4-spaces {
  @include spacing-classes($space-num);
}
