@import 'constants';

$module: 'SubNav';

.#{$module} {
  transition: max-height $long-duration;
  max-height: 0;
  overflow: hidden;

  &--open {
    max-height: 200px;
    border-bottom: 1px solid $gray-medium-light;
  }
}
