@import 'constants';

$module: 'TextInput';

.#{$module} {
  @include input-container-styles();

  background-color: $white;
  height: $xxl - 2 * $border-width;
  overflow: hidden;

  &--textarea {
    height: auto;
  }

  &--search {
    height: 43px;

    .#{$module}__input {
      @include input-styles();
      @include match-parent-height();

      height: 44px;
    }
  }

  &--stretch {
    width: stretch;
  }

  &--no-left-border-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &--no-right-border-radius {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__input {
    @include input-styles();
    @include match-parent-height();

    &--inline {
      width: 100% !important;
    }

    &[disabled] {
      background-color: white;
      opacity: 0.2;
    }
  }

  &__textarea-input {
    @include input-styles();

    min-height: $xxxl;
    width: 100% !important;

    &::placeholder {
      font-size: inherit;
    }
  }

  &__clear-zone {
    @include clear-zone-styles($module);
  }

  &__clear {
    @include clear-styles();

    height: 22px;
  }

  &__icon-zone {
    @include icon-zone-styles();
  }

  &__icon {
    @include icon-styles();
  }

  &--errorborder {
    border: 1px solid $red !important;
  }
}
