@import 'constants';

$module: 'Tab';
$cover-border: $border-width solid $white;
$marker-width: 2px;

.#{$module} {
  border: $cover-border;
  border-bottom: $default-border;
  color: $gray-dark;
  display: inline-block;
  font-size: $fs14;
  font-weight: $fw600;
  padding: $xs $md;
  position: relative;
  transition: color $long-duration;

  &--large {
    font-size: $fs16;
    padding: 20px;
  }

  &:hover {
    color: darken($purple, 12%);
    text-decoration: none;
  }

  &--active {
    color: var(--primary700) !important;

    .#{$module}__marker {
      left: -$border-width;
      transition: width $long-duration, left $long-duration;
      width: calc(100% + 2 * #{$border-width});
    }

    &:focus {
      color: var(--primary700);
    }
  }

  &--upgrade-required {
    opacity: $disabled-opacity;
  }

  &__marker {
    background-color: var(--primary700);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    bottom: $border-width - $marker-width;
    height: $marker-width;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    width: 0;

    &--isEmbedded {
      background-color: var(--primary500)
    }
  }

  &__content {
    white-space: nowrap;
  }
}
