$input-height: $xxl;
$input-height-narrow: $lg;
$input-font-size: $fs16;
$placeholder-font-size: $fs16;
$label-font-size: $fs16;

@mixin input-container-styles() {
  border: $tiny solid var(--mono300);
  border-radius: $border-radius;
  height: $input-height;
  position: relative;

  &--narrow {
    height: $input-height-narrow !important;
  }

  &--tall {
    height: 115px !important;

    textarea {
      height: 115px !important;
    }
  }

  &--unbordered {
    border: none;
  }

  &--fullwidth {
    width: 100%;
  }
}

@mixin input-styles() {
  align-items: center !important;
  border: none !important;
  display: flex !important;
  font-size: $input-font-size;
  width: calc(100% - #{$xl}) !important;

  &:disabled {
    width: 100% !important;
  }

  &:focus,
  &:hover {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  &::placeholder {
    font-size: $placeholder-font-size;
  }
}

@mixin match-parent-height() {
  height: 100% !important;
}

@mixin zone-styles() {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition-duration: $duration;
  width: $xl;

  &--show {
    opacity: 1;
    pointer-events: auto;

    &:hover {
      cursor: pointer;
    }
  }
}

@mixin clear-zone-styles($module) {
  @include zone-styles();

  &--show {
    .#{$module}__clear {
      &:hover {
        color: $red;
      }
    }
  }
}

@mixin clear-styles() {
  // To match SelectInput's "x" styles
  color: $gray-dark;
  font-size: 18px;
  font-weight: $base-font-weight;
  line-height: 1;
  transition-duration: $duration;
}

@mixin icon-zone-styles() {
  @include zone-styles();
}

@mixin icon-styles() {
  color: $gray-dark;
}
