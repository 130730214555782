@import 'constants';
@import 'mixins';

$module: 'NumberInput';

.#{$module} {
  @include input-container-styles();

  height: $xxl - 2 * $border-width;
  min-width: $xxxl;
  width: $xxxl;

  &--square {
    min-width: $xxl;
    width: $xxl + 5;

    .#{$module}__input {
      text-align: center;
    }
  }

  &--inline {
    display: inline-block;

    .#{$module}__input {
      width: 100% !important;
    }
  }

  &--disabled {
    @include disabled();
  }

  &__input {
    @include input-styles();
    @include match-parent-height();

    min-width: $xxl;
  }

  &--numberWithSuffix {
    width: 80px;
    height: 40px;

    .#{$module}__input {
      padding-right: 0;
      width: calc(100% - 5px) !important;
    }
  }
}
