@import 'constants';

$module: 'BasicTextField';

.#{$module} {
  input {
    &:focus,
    &:focus:invalid:focus {
      border: none;
      box-shadow: none;
      outline: 0;
    }
  }

  &--label-default-focused {
    color: $purple !important;
  }

  &--label-hiring-focused {
    color: $navy !important;
  }

  &--centered {
    input {
      text-align: center;
    }
  }

  &--bordered {
    input {
      box-sizing: border-box !important;
      transition: border-color 0.15s;
      border: 1px solid $gray-medium !important;
      padding: 0 $xxs !important;

      &:focus {
        box-sizing: border-box !important;
        transition: border-color 0.15s;
        border: 2px solid $purple !important;
      }
    }

    hr {
      display: none !important;
    }
  }

  &--disabled {
    pointer-events: none;

    input {
      background: $gray-light !important;
    }

    hr {
      display: none !important;
    }
  }

  &--success-text {
    hr {
      border-color: $green !important;
    }

    div {
      color: $green !important;
    }
  }

  &--errorborder {
    input {
      border: 1px solid $red !important;
    }
  }
}
