@import 'constants';

$module: 'Avatar';

.#{$module} {
  border-radius: 50%;
  object-fit: cover;

  &--shadow {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  }

  &--isEmbedded {
    filter: hue-rotate(220deg);
  }
}
