$cursive: CedarvilleCursive-Regular;

// Font weights
$fw300: 300;
$fw400: 400;
$fw500: 500;
$fw600: 600;
$fw700: 700;
$fw800: 800;

// Font Sizes
$fs10: 10px;
$fs12: 12px;
$fs14: 14px;
$fs16: 16px;
$fs18: 18px;
$fs24: 24px;
$fs30: 30px;
$fs32: 32px;
$fs36: 36px;
$fs40: 40px;
$fs50: 50px;

// Base Font
$base-font-family: "Plus Jakarta Sans", sans-serif;
$base-font-weight: $fw500;
$base-font-size: $fs12;
$base-line-height: 1.4;

// Fixed Font
$fixed-font-family: monospace;
$fixed-font-size: 85%;
$fixed-line-height: $base-line-height;

// Headings
$header-font-weight: $fw700;
$header: $header-font-weight;

$caveat: "Caveat", cursive;
