@import 'constants';

$module: 'UpgradeButtonNavItem';

.#{$module} {
  position: relative;

  &__notification-indicator {
    display: inline-block;
    position: absolute;
    top: -2px;
    right: -2px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: $red;
  }
}

button.#{$module}__button {
  height: auto;
  line-height: 1.5;
  padding: 1px $xs;

  &:hover {
    background-color: $blue;
    color: $white;
  }
}
