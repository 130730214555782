@import 'constants';

$module: 'LeftPanelReferralBanner';

.#{$module} {
  display: grid;
  grid-template-rows: 1fr 6fr 2fr;

  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-color: rgba($mono900, 0.6);
  background-image: url('../referral_bg.jpg');
}
