@import 'constants';
@import 'mixins';

$delay-factor: 100ms;
$module: 'LoadingIndicator';

$three-dots-base: 8px;
$gray-bar-width: 48px;

.#{$module} {
  &--centered {
    margin: 0 auto;

    .#{$module}__child {
      margin: 0 auto;
    }
  }

  &--h-abs-centered {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  &--f-centered {
    left: 50vw;
    position: fixed;
    top: 50vh;
    transform: translateX(-50%) translateY(-50%);
  }

  &--three-dots {
    color: var(--primary700);
    height: $three-dots-base;
    width: $three-dots-base * 4;

    .#{$module}__child {
      animation: threeDots 0.9s ease-in-out alternate infinite;
      animation-delay: 0.3s;
      border-radius: 50%;
      height: $three-dots-base;
      left: $three-dots-base * 2 - $three-dots-base / 2;
      position: relative;
      top: -$three-dots-base;
      width: $three-dots-base;

      &::before,
      &::after {
        animation: threeDots 0.9s ease-in-out alternate infinite;
        border-radius: 50%;
        content: '';
        height: $three-dots-base;
        position: absolute;
        width: $three-dots-base;
      }

      &::before {
        animation-delay: 0s;
        left: -($three-dots-base * 2 - $three-dots-base / 2);
      }

      &::after {
        animation-delay: 0.6s;
        right: -($three-dots-base * 2 - $three-dots-base / 2);
      }
    }
  }

  &--big-clock {
    .#{$module}__child {
      background-color: transparent;
      border-radius: 50%;
      box-shadow: inset 0 0 0 6px var(--primary700);
      height: 60px;
      position: relative;
      width: 60px;

      &::before {
        animation: hrhand 8s linear infinite;
        background-color: var(--primary700);
        border-radius: 20px;
        content: '';
        height: 6px;
        left: 28px;
        position: absolute;
        top: 28px;
        transform-origin: 3px 3px;
        width: 16px;
      }

      &::after {
        animation: minhand 2s linear infinite;
        background-color: var(--primary700);
        border-radius: 32px;
        content: '';
        height: 6px;
        left: 28px;
        position: absolute;
        top: 28px;
        transform-origin: 3px 3px;
        width: 24px;
      }
    }
  }

  /*
   * Square positions
   * 1 2 3
   * 4 5 6
   * 7 8 9
   */
  &--big-h {
    display: inline-block;

    .#{$module}__square {
      animation: logoSquaresScaleDelay 3s infinite ease-in-out;
      background-color: var(--primary700);
      float: left;
      height: 33.33%;
      width: 33.33%;

      &--1 {
        animation-delay: $delay-factor * 2;
      }

      &--2 {
        animation-delay: $delay-factor * 3;
        background-color: transparent;
      }

      &--3 {
        animation-delay: $delay-factor * 4;
      }

      &--4 {
        animation-delay: $delay-factor * 1;
      }

      &--5 {
        animation-delay: $delay-factor * 2;
      }

      &--6 {
        animation-delay: $delay-factor * 3;
      }

      &--7 {
        animation-delay: $delay-factor * 0;
      }

      &--8 {
        animation-delay: $delay-factor * 1;
        background-color: transparent;
      }

      &--9 {
        animation-delay: $delay-factor * 2;
      }
    }
  }

  &--gray-bar {
    height: 6px;
    width: $gray-bar-width;
    border-radius: 3px;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: grayBarShimmer;
    animation-timing-function: linear;
    background: linear-gradient(258.45deg, $gray-light 5.54%, $gray-medium-light 32.9%, $gray-light 64.62%);
  }

  @keyframes logoSquaresScaleDelay {
    0%,
    50%,
    100% {
      transform: scale3d(1, 1, 1);
    }

    25% {
      transform: scale3d(0, 0, 1);
    }
  }

  @keyframes minhand {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes hrhand {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes threeDots {
    0% {
      box-shadow: 0 8px 0 -8px var(--primary700);
    }

    100% {
      box-shadow: 0 8px 0 var(--primary700);
    }
  }

  @keyframes grayBarShimmer {
    0% {
      background-position: -$gray-bar-width 0;
    }

    100% {
      background-position: $gray-bar-width 0;
    }
  }
}
