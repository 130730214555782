@import 'constants';
@import 'mixins';

$module: 'BenefitsWidget';

.#{$module} {
  .DateInput {
    &:focus-within {
      border: 2px solid $purple500 !important;
    }
  }

  .TextInput {
    &:focus-within {
      min-width: 100%;
      border: 2px solid $purple500 !important;
    }
  }

  .NumberInput {
    &:focus-within {
      border: 2px solid $purple500 !important;
    }
  }

  .SelectInput {
    &:focus-within {
      min-width: 100%;
      border: 2px solid $purple500 !important;
    }
  }
}
