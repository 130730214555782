$module: 'Loader';

.#{$module} {
  &--centered {
    left: 50vw;
    position: fixed;
    top: 50vh;
    transform: translateX(-50%) translateY(-50%);
  }

  &--centered-relative {
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
  }

  &--centeredAbsolute {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &__transition {
    &-enter {
      opacity: 0.01;

      &.#{$module}__transition-enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
      }
    }

    &-exit {
      opacity: 1;

      &.#{$module}__transition-exit-active {
        opacity: 0;
        transition: opacity 10ms ease-out;
      }
    }
  }
}
