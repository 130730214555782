// Note: Hardcoded fe-design-base (FDB) colors due to sass compile issues with using new variables

// FDB $purple100
$pinkPale: #f1ecff;
$lilac: #f1ecff;
$primary050: #f1ecff;

// FDB $purple300
$violet-light: #cdc0f3;
$purplePinkLight: #cdc0f3;
$fiolet: #cdc0f3;
$purple-light: #cdc0f3;

// FDB $purple400
$purple400: #9c7edb;

// FDB $purple500
$primaryA3: #7e3dd4;
$pink: #7e3dd4;
$purplePinkMedium: #7e3dd4;
$primaryA1: #7e3dd4;

// FDB $purple700
$primaryA2: #52258f;
$purple-dark: #52258f;
$violet-dark: #52258f;
$purple: #52258f;

// FDB $purple900
$purple-xdark: #1e0b3a;

// FDB $teal300
$aqua: #11ada2;
$irisBlue: #11ada2;

// FDB $teal500
$blue-dark: #037a72;

// FDB $mono0
$white: #fff;
$ghostWhite: #fff;

// FDB $mono100
$blue-duck-egg: #f2f2ec;
$gray-light: #f2f2ec;
$gray94: #f2f2ec;
$concreteGrey: #f2f2ec;

// FDB $mono300
$gray-medium-light: #e6e4d6;
$santorini: #e6e4d6;
$gray-medium: #e6e4d6;

// FDB $mono500
$monoGray: #9e9c88;
$neutral300: #9e9c88;
$gray77: #9e9c88;

// FDB $mono700
$neutral500: #605f56;
$gray-xdark: #605f56;
$monoDark: #605f56; //renamed from mono800 to prevent name conflict
$gray-dark: #605f56;
$sealGrey: #605f56;
$brightGrey: #605f56;
$neutral600: #605f56;
$neutral700: #605f56;
$navy-light: #605f56;
$darkGray: #605f56;

// FDB $mono900
$eerieBlack: #1e0b3a;
$oxford-blue: #1e0b3a;
$navy: #1e0b3a;
$primary-text: #1e0b3a;

// FDB $green100
$green-lightest: #e5f4e7;

// FDB $green300
$green-light: #028810;
$green: #028810;
$lime: #028810;

// FDB $green500
$green-dark: #155e34;

// FDB $blue100
$frostedMint: #e9f7ff;
$blue-lightest: #e9f7ff;
$secondaryA2: #e9f7ff;

// FDB $blue300
$blue: #0177b0;
$blue-payments-hub: #0177b0;
$b500: #0177b0;
$blue-light: #0177b0;
$blue-medium: #0177b0;
$blue-sky: #0177b0;

// FDB $blue500
$blue2: #005781;
$blue-steel: #005781;
$blue-royal: #005781;
$blue-facebook: #005781;

// FDB $orange100
$yellow-medium-dark: #fdf3e6;
$yellow-light: #fdf3e6;
$yellow-medium: #fdf3e6;
$shift01: #fdf3e6;
$yellow: #fdf3e6;

// FDB $orange300
$yellow-dark: #eb7f00;
$shift02: #eb7f00;
$orange-yellow: #eb7f00;
$orange: #eb7f00;

// FDB $orange500
$orange-dark: #c0490a;
$orange-pay-anywhere: #c0490a;

// FDB $red100
$alertA1: #fff0ee;
$orange-light: #fff0ee;
$pink-light: #fff0ee;

// FDB $red300
$orange-indeed: #d72505;
$orange-indeed-dark: #d72505;
$red: #d72505;
$red-orange: #d72505;

// FDB $red500
$red3: #a32100;
$red-warning: #a32100;
$red2: #a32100;

// Misc
$border-color: $gray-medium-light;
$error-color: $red;

// vestigial
$white-medium: $gray-light;
$black-light: $navy;
$black: $navy;
$gray-lightest: $gray-light;
$gray-medium-lighter: $gray-medium-light;
$gray-medium-dark: $gray-dark;
$red-medium: $red;
$navy-dark: $navy;
$navy-medium: $navy-dark;
$purple-lighter: $purple-light;
$purple-medium: $purple;
$violet: $purple-light;
$violet-medium: $purple-light;
$violet-dark: $purple-light;
$violet-xdark: $purple-dark;
