@import 'constants';

$module: 'Text';

.#{$module} {
  line-height: $base-line-height;

  // font-weight
  &--fw300 {
    font-weight: $fw300;
  }

  &--fw400 {
    font-weight: $fw400;
  }

  &--fw600 {
    font-weight: $fw600;
  }

  &--fw700 {
    font-weight: $fw700;
  }

  &--fw800 {
    font-weight: $fw800;
  }

  // font-family
  &--mono {
    font-family: $fixed-font-family;
  }

  &--gotham {
    font-family: GothamRounded;
  }

  // font-style
  &--italic {
    font-style: italic;
  }

  // font-size
  &--fs10 {
    font-size: $fs10;
  }

  &--fs12 {
    font-size: $fs12;
  }

  &--fs14 {
    font-size: $fs14;
  }

  &--fs16 {
    font-size: $fs16;
  }

  &--fs18 {
    font-size: $fs18;
  }

  &--fs24 {
    font-size: $fs24;
  }

  &--fs30 {
    font-size: $fs30;
  }

  &--fs32 {
    font-size: $fs32;
  }

  &--fs36 {
    font-size: $fs36;
  }

  &--fs40 {
    font-size: $fs40;
  }

  &--fs50 {
    font-size: $fs50;
  }

  // text-decoration
  &--underline {
    text-decoration: underline;
  }

  &--strikethrough {
    text-decoration: line-through;
  }

  // text-transform
  &--capitalize {
    text-transform: capitalize;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  // "all-in-one" -> "All-in-one"
  &--capitalizeFirstLetter {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  // preserve whitespace
  &--with-whitespace {
    white-space: pre-line;
  }

  &--break-word {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-break: break-all;
    word-wrap: break-word;
  }

  // color
  &--white {
    color: $white;
  }

  &--white-medium {
    color: $white-medium;
  }

  &--black {
    color: $black;
  }

  &--black-light {
    color: $black-light;
  }

  &--gray-lightest {
    color: $gray-lightest;
  }

  &--gray-light {
    color: $gray-light;
  }

  &--gray-medium-lighter {
    color: $gray-medium-lighter;
  }

  &--gray-medium-light {
    color: $gray-medium-light;
  }

  &--gray-medium {
    color: $gray-medium;
  }

  &--gray-medium-dark {
    color: $gray-medium-dark;
  }

  &--gray-dark {
    color: $gray-dark;
  }

  &--gray-xdark {
    color: $gray-xdark;
  }

  &--red {
    color: $red;
  }

  &--red-medium {
    color: $red-medium;
  }

  &--red-warning {
    color: $red-warning;
  }

  &--red-orange {
    color: $red-orange;
  }

  &--orange {
    color: $orange;
  }

  &--orange-dark {
    color: $orange-dark;
  }

  &--orange-yellow {
    color: $orange-yellow;
  }

  &--yellow {
    color: $yellow;
  }

  &--yellow-light {
    color: $yellow-light;
  }

  &--yellow-medium {
    color: $yellow-medium;
  }

  &--yellow-dark {
    color: $yellow-dark;
  }

  &--aqua {
    color: $aqua;
  }

  &--lime {
    color: $lime;
  }

  &--pink-light {
    color: $pink-light;
  }

  &--pink {
    color: $pink;
  }

  &--green-lightest {
    color: $green-lightest;
  }

  &--green-light {
    color: $green-light;
  }

  &--green {
    color: $green;
  }

  &--green-dark {
    color: $green-dark;
  }

  &--blue-lightest {
    color: $blue-lightest;
  }

  &--blue-light {
    color: $blue-light;
  }

  &--blue {
    color: $blue;
  }

  &--blue-medium {
    color: $blue-medium;
  }

  &--blue-dark {
    color: $blue-dark;
  }

  &--blue-steel {
    color: $blue-steel;
  }

  &--blue-royal {
    color: $blue-royal;
  }

  &--blue-facebook {
    color: $blue-facebook;
  }

  &--blue-sky {
    color: $blue-sky;
  }

  &--navy-light {
    color: $navy-light;
  }

  &--navy {
    color: $navy;
  }

  &--navy-medium {
    color: $navy-medium;
  }

  &--navy-dark {
    color: $navy-dark;
  }

  &--purple-light {
    color: $purple-light;
  }

  &--purple {
    color: $purple;
  }

  &--purple-medium {
    color: $purple-medium;
  }

  &--purple-dark {
    color: $purple-dark;
  }

  &--purple-xdark {
    color: $purple-xdark;
  }

  &--dark-gray {
    color: $darkGray;
  }

  &--violet {
    color: $violet;
  }

  &--violet-medium {
    color: $violet-medium;
  }

  &--violet-dark {
    color: $violet-dark;
  }

  &--violet-xdark {
    color: $violet-xdark;
  }

  &--neutral-300 {
    color: $neutral300;
  }

  &--neutral-600 {
    color: $neutral600;
  }

  &--neutral-700 {
    color: $neutral700;
  }

  &--shift-01 {
    color: $shift01;
  }

  &--eerie-black {
    color: $eerieBlack;
  }

  &--mono-dark {
    color: $monoDark;
  }

  &--oxford-blue {
    color: $oxford-blue;
  }

  &--purple-400 {
    color: var(--primary400);
  }

  // other
  &--underlineHover:hover {
    text-decoration: underline !important;
  }

  &--colon {
    &::after {
      content: ':';
    }
  }

  &--errorLink {
    a {
      color: $red !important;
      font-weight: $fw400;
      text-decoration: underline;
    }
  }
}
