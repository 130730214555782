@import 'constants';

$module: 'LeftPanelPayrollEoy';

.#{$module} {
  display: grid;
  grid-template-rows: 3fr 6fr;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-color: rgba($mono900, 0.6);
  background-image: url('../images/background.jpg');

  &__custom_h1 {
    font-weight: 400 !important;
  }

  &__content {
    justify-self: center;
  }
}
