@import 'constants';
$module: 'ProgressBar';

.#{$module} {
  position: relative;
  width: 100%;
  border-radius: 8px;
  border-width: thin;
  border-style: none;
  border-color: $mono700;
  overflow: hidden;

  &--default {
    background-color: #dedfe2;
  }

  &--green100 {
    background: $green100;
  }

  .bar-inner {
    &-success {
      background-color: $green300;
    }

    &-error {
      background-color: $red300;
    }

    &-b500 {
      background-color: $blue300;
    }

    &-teal300 {
      background-color: $teal300;
    }

    &-purple {
      background-color: var(--primary500);
    }

    &-accent500 {
      background-color: $accent500;
    }
  }
}
