@import 'constants';
@import 'mixins';

$module: 'CheckboxInput';

.#{$module} {
  &--sm {
    transform: scale(0.8);
    transform-origin: top left;
  }

  &--disabled {
    @include disabled();
  }

  &--hideHtmlInput {
    input {
      display: none;
    }
  }
}
