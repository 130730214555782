@import 'constants';
@import 'mixins';

$module: 'ViewContainer';

.#{$module} {
  height: calc(100vh - #{$nav-height});

  &--isEmbedded {
    height: 100vh;
    position: relative;
  }

  @include tablet {
    position: relative;
  }

  @include ipad-portrait() {
    height: $ipad-height - $nav-height - $default-footer-height;
    position: relative;
  }

  @include ipad-landscape() {
    height: $ipad-width - $nav-height - $default-footer-height;
    position: relative;
  }

  &:has(.SignInView, .signUpV3Root, .CompanyProfileView, .QSG_LandingPage, .FocusedQSG, .cloverSignUpRoot, .cloverEmbeddedSignUpRoot, .lightspeedSignUpRoot .ExtendedQuestionsView) {
    height: 100vh;
  }

  &__inner {
    background: $mono100;
    color: $navy;
    height: 100%;
    position: absolute;
    width: 100%;
    overflow: auto;

    &:has(.CloverEmbedded) {
      background-color: $mono0 !important;
    }

    @media print {
      // we need to force html root overflow, for print to show multiple pages
      overflow: unset;
    }

    &:has(.SignInView, .signUpV3Root, .cloverSignUpRoot, .lightspeedSignUpRoot .cloverEmbeddedSignUpRoot) {
      background-color: $mono0 !important;
    }

    &:has(.cloverSignUpRoot, .lightspeedSignUpRoot, .cloverEmbeddedSignUpRoot, .EarningsView) {
      height: 100vh;
    }
  }
}
