@import 'constants';

$module: 'IconButton';

.#{$module} {
  align-items: center;
  display: flex;
  flex-basis: $xxl;
  height: $xxl;
  justify-content: center;

  // For Safari
  max-width: $xxl;
  min-width: $xxl;
  padding: 0 !important;
  width: $xxl;

  &--no-border,
  &--no-border:hover,
  &--no-border:focus,
  &--no-border:active,
  &--no-border:visited {
    background: transparent !important;
    border: none !important;
  }

  &--cramped {
    max-width: $md;
    min-width: $md;
    width: $md;
  }
}
