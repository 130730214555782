@import 'constants';

$module: 'ToggleInput';

.#{$module} {
  @mixin MuiSwitchStyle($color, $backgroundColor) {
    .MuiSwitch-switchBase.Mui-checked {
      color: $color;

      &:hover {
        background-color: rgba($color, 0.1);
      }

      & + .MuiSwitch-track {
        background-color: $backgroundColor;
        opacity: 1;
      }
    }
  }

  .MuiSwitch-switchBase .MuiSwitch-input {
    position: absolute;
    width: 300%;
  }

  &--theme-default {
    @include MuiSwitchStyle($green, $green-lightest);
  }

  &--theme-purple {
    @include MuiSwitchStyle($purple, $purple-lighter);
  }

  // Only used as temporary theme for biller UI - do not reuse!!!
  &--theme-blue-medium {
    @include MuiSwitchStyle($blue-medium, $blue-lightest);
  }
}
