@import 'constants';

$module: 'LaunchWeekStoryPaginationStep';

.#{$module} {
  --award-steps-delay-ms: 3000ms;

  border-radius: 8px;

  &:last-child {
    margin-right: 20px;
  }

  &__current {
    display: flex;
    background: linear-gradient(to right, $accent500 50%, white 0);
    background-size: 200% 100%;
    background-position: right;
    animation: makeItfadeIn var(--award-steps-delay-ms) 0.3s forwards;
  }

  @keyframes makeItfadeIn {
    100% {
      background-position: left;
    }
  }
}
