@import 'constants';
@import 'mixins';

$module: 'ActiveTrialNavItem';

.#{$module} {
  padding: 0 8px;

  @media (min-width: 1025px) and (max-width: 1233px) {
    &__text {
      display: none;
    }
  }

  &__arrow {
    display: none;
  }

  &--trialOpen {
    .#{$module}__link {
      background-color: $purple;
      border-radius: $xs;
      padding: 0 $xs;
      position: relative;
      z-index: 1000;
    }

    .#{$module}__arrow {
      display: block;
      height: 100px;
      left: 0;
      position: absolute;
      top: calc(100% + 20px);
      width: 100px;
    }
  }
}
