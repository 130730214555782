@import 'constants';
@import 'mixins';

$module: 'Modal';
$tmask-color: rgba($mono900, 0.6);

.#{$module} {
  @media (max-width: $mobile-breakpoint) {
    &--fullScreenAtMobile {
      max-height: 100% !important;
      height: 100%;
      max-width: 100%;
      padding: 0;
      width: 100% !important;
    }
  }

  background-color: $white;
  border-radius: $border-radius;
  left: 50%;
  max-width: $default-form-width;
  padding: $md;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 85%;

  &--large {
    height: 90%;
    max-width: 90%;
    padding: 0;
    width: 90%;
  }

  &--medium {
    height: 80%;
    max-width: 80%;
    padding: 0;
    width: 80%;
  }

  &--circle {
    min-height: 500px;
    border-radius: 50%;
  }

  &--rounded {
    border-radius: 5%;
  }

  &--roundedSmall {
    border-radius: 2%;
  }

  &--stretched {
    max-width: 75vw;
  }

  &--fullScreen {
    height: 100%;
    max-width: 100%;
    padding: 0;
    width: 100%;
  }

  &--auto {
    max-width: none;
    width: auto;

    @include mobile() {
      width: 95%;
    }
  }

  &--noPadding {
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  &__close-icon {
    z-index: 1;
    position: absolute;
    right: $xxs;
    top: $xxs;

    &--closeIconInsideMore {
      right: $lg;
      top: $lg;
    }

    &--outsideTop {
      top: -36px;
      right: 12px;
    }

    &--outsideRight {
      top: 0;
      right: -31px;

      &:hover.ModalCloseIcon {
        background-color: transparent;
      }
    }
  }

  &__overlay {
    background-color: $tmask-color;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    &--isEmbedded {
      background-color: rgba(0, 0, 0, 0.6); // embedded mono900
    }
  }

  &__transparent_overlay {
    background-color: rgba(25, 35, 41, 0);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &__publish_button,
  &__get_time_clock_highlight,
  &__departments_link {
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      box-shadow: 0 0 0 9999px $tmask-color;
      height: 125px;
      width: 125px;
    }
  }

  &__publish_button::before {
    height: 200px;
    width: 200px;
    top: 18px;
    right: 15px;
  }

  &__departments_link::before {
    top: 5px;
    left: 95px;
  }

  &__prefer_paper_checks_button {
    background-color: rgba(25, 35, 41, 0);
    bottom: 40px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &__get_time_clock_highlight::before {
    top: 5px;
    left: 250px;
    box-shadow: 0 0 0 9999px rgba(25, 35, 41, 0.85);
  }

  &__time_off_settings {
    background-color: $tmask-color;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100 !important;
  }
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content {
  max-height: 93vh;
  opacity: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  transition-duration: $duration;

  &.#{$module}--large {
    max-height: 90%;
  }

  &.#{$module}--circle {
    overflow-y: hidden;
  }

  &.#{$module}--fullScreen {
    max-height: 100%;
  }

  &.#{$module}--overflowVisible {
    overflow-y: visible;
  }

  &.#{$module}--transparent {
    background-color: transparent;
  }

  &.#{$module}--transparentOverlayWithShadow {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  }

  &--after-open {
    opacity: 1;
  }

  &.#{$module} {
    &--fullWidth {
      width: 100%;
      box-sizing: border-box;
      max-height: 100vh;
      max-width: 100vw;
      border-radius: 0;
      border: none;
    }
  }
}
