@import 'constants';
@import 'mixins';

$module: 'BottomDrawer';

.#{$module} {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-bottom-drawer;
  display: flex;
  justify-content: center;

  &__BG {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000085;
  }

  &__Card {
    position: absolute;
    width: 100%;
    overflow-y: auto;
  }

  &__FooterBar,
  &__ActionsBar {
    padding: 0 12px;
    background: #fff;
    height: 80px;
  }

  &__ActionsBar {
    border-radius: 20px 20px 0 0;

    &.NavActionsBar {
      @media (min-width: $mobile-breakpoint) {
        padding: 0 32px;
      }
    }
  }

  &__HeaderContent {
    background: #fff;
    min-height: 80px;
    border-radius: 20px 20px 0 0;
  }

  &__EmbeddedHeader {
    background: var(--mono0);
  }

  @media (max-width: $tablet-breakpoint) {
    &__HeaderContent {
      border-radius: 12px 12px 0 0;
    }
  }

  &__content_wrapper {
    flex: 1;
    background: var(--mono100);
    overflow-y: scroll;

    &--isEmbedded {
      background: var(--mono0);
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  & .ProgressBar {
    height: 5px;
  }

  .BulkAddModal__header_wrapper {
    padding: 24px;
  }

  .BulkAddModal__header {
    padding-bottom: 8px;
    line-height: 30px !important;
  }

  .BulkAddModal__description {
    line-height: 20px !important;
  }
}
