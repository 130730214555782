@import 'constants';

$module: 'SwitchToPayrollBanner';

.#{$module} {
  &__pill {
    .FDBPill {
      color: $mono0 !important;
      background-color: $accent900 !important;
    }
  }
}
