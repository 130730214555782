@import 'lib/fe-design-base/styles/colors';
$module: 'FDBText';

.#{$module} {
  &--italic {
    font-style: italic;
  }

  &--underline {
    text-decoration: underline;
  }

  &--strikethrough {
    text-decoration: line-through;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--lowercase {
    text-transform: lowercase;
  }

  &--titlecase {
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &--inline {
    display: inline;
  }

  &.required {
    &::after {
      color: $red300;
      content: " *";
    }
  }
}
