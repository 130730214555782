@import 'constants';

$module: 'AvatarMenuNavItem';

.#{$module} {
  &__avatar {
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }
  }

  &__menu-item {
    color: var(--primary700);

    &:hover {
      color: $accent900;
    }
  }

  &__menu-item--mobile {
    color: $purple-light;
    font-weight: 400;
    font-size: 14px;
  }

  &__menu-item-container--mobile {
    min-height: 30px !important; // to override default styles from material-ui/MenuItem
  }
}
