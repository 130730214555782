$module: 'AnnouncementBanner';

.#{$module} {
  // undo override from app/assets/stylesheets/base/_sg-typography.scss:22
  // in favor of default banner style
  p {
    color: inherit;
    margin: 0;
  }
}
