$module: 'ContactSection';

.#{$module} {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px 24px;
  }

  &__take-two {
    grid-column: span 2;
  }
}
