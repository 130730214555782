@import 'colors';

// constants that can be adjusted:
$animating-speed: 1.5s;
$position: absolute;
$after-inset: -2px;
$before-inset: 0;
$after-z-index: -2;
$before-z-index: -1;

// Pseudo-element styling for ai borders
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@mixin ai-border-after {
  content: '';
  position: $position;
  inset: $after-inset;
  z-index: $after-z-index;
  background-image:
    conic-gradient(
      from var(--angle),
      $purple500,
      $purple500,
      $accent500,
      $accent500,
      $highlight500,
      $accent500,
      $purple500
    );
}

@mixin ai-border-before {
  content: '';
  position: $position;
  inset: $before-inset;
  z-index: $before-z-index;
}

@mixin ai-animate-border {
  @include ai-border-after;

  animation: $animating-speed spin linear infinite;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }

  to {
    --angle: 360deg;
  }
}
