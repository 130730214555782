@import 'constants/animations';
@import 'constants/breakpoints';
@import 'fe-design-base/styles/colors';
@import 'fe-design-base/styles/breakpoints';
@import 'fe-design-base/styles/zindex';
@import 'fe-design-base/styles/_shadows';
@import 'constants/colors';
@import 'constants/dimensions';
@import 'constants/rails';
@import 'constants/typography';

// Composite
$default-border: $border-width solid $gray-medium;
$light-medium-border: $border-width solid $gray-medium-light;
$white-border: $border-width solid $white;
$light-border: $border-width solid $gray-light;

$bottom-right-box-shadow: $xxxxs $xxxxs 0 0 rgba(0, 0, 0, 0.08);

$disabled-opacity: 0.6;
$archived-opacity: 0.5;

$mui-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;

// Specific
@import 'constants/forms';
