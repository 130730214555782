@import 'constants';

$module: 'NextIntegrationDialog';

.#{$module} {
  .next-frame-embed-iframe {
    width: 100%;
    height: 645px;
  }

  .FDBIconButton {
    z-index: 10001;
  }
}
