/* fe-design-base breakpoint mixins - start */
@mixin small {
  @media (min-width: $small-breakpoint) {
    @content;
  }
}

@mixin medium {
  @media (min-width: $medium-breakpoint) {
    @content;
  }
}

@mixin large {
  @media (min-width: $large-breakpoint) {
    @content;
  }
}

@mixin xlarge {
  @media (min-width: $xlarge-breakpoint) {
    @content;
  }
}

@mixin xxlarge {
  @media (min-width: $xxlarge-breakpoint) {
    @content;
  }
}

/* fe-design-base breakpoint mixins - end */

/* Deprecated breakpoints - start */
@mixin large-desktop {
  @media (min-width: $large-desktop-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin ipad-portrait {
  @media (device-width: $ipad-width) and (device-height: $ipad-height) and (orientation: portrait) {
    @content;
  }
}

@mixin ipad-landscape {
  @media (device-width: $ipad-width) and (device-height: $ipad-height) and (orientation: landscape) {
    @content;
  }
}

/* Deprecated breakpoints - end */

@mixin print {
  @media print {
    @content;
  }
}

// Sometimes necessary when printing in FF to ensure content isn't cut
// off after the first page. Details: http://stackoverflow.com/q/4609007
@mixin print-fix() {
  display: block !important;
  overflow: visible !important;
  position: static !important;
}

@mixin truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin disabled() {
  opacity: $disabled-opacity;
  pointer-events: none;
}

@mixin wipe-link-styles() {
  color: inherit;
  cursor: inherit;
  font-weight: inherit;
  transition-duration: 0s !important;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }
}

@mixin transform-center() {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin loadable($loading-opacity: $disabled-opacity, $loaded-opacity: 1) {
  opacity: $loaded-opacity;
  transition: opacity $duration;

  &--loading {
    opacity: $loading-opacity;
  }
}

@mixin column-shadow() {
  position: relative;

  &::before {
    box-shadow: -13px 0 15px -15px rgba(0, 0, 0, 0.12) inset;
    content: " ";
    height: 100%;
    top: 0;
    left: -15px;
    position: absolute;
    width: 15px;
  }

  &::after {
    box-shadow: 13px 0 15px -15px rgba(0, 0, 0, 0.12) inset;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    right: -15px;
    width: 15px;
  }
}

@mixin button-color-filled($text-color, $color, $hover-color) {
  background-color: $color;
  border-color: $color;
  color: $text-color;

  &:focus,
  &:active {
    color: $text-color;
  }

  &:hover {
    background-color: $hover-color;
    border-color: $hover-color;
    color: $text-color;
    background-image: none;
  }

  &.#{$module}--unclickable {
    &:hover {
      background-color: $color;
      border-color: $color;
      color: $text-color;
    }
  }

  &:disabled {
    border-color: $color;
  }
}

@mixin button-color-outlined($color, $bg-color) {
  background-color: $white;
  border-color: $color;
  color: $color;

  &:focus,
  &:active {
    color: $color;
  }

  &:hover {
    background-color: $bg-color;
    border-color: darken($color, 5%);
    color: darken($color, 5%);

    .Icon {
      color: darken($color, 5%);
    }
  }

  &.#{$module}--unclickable {
    &:hover {
      background-color: $white;
      border-color: $color;
      color: $color;
    }
  }

  &:disabled {
    border-color: $color;
  }
}

@mixin button-link($color, $hover-color) {
  background-color: transparent !important;
  border: none !important;
  color: $color;
  height: auto;
  padding: 0 !important;
  font-weight: $fw600;

  &:focus,
  &:active,
  &:visited {
    color: $color !important;
  }

  &:hover {
    color: $hover-color !important;
    background-image: none;
  }

  &:disabled {
    border-color: $color;
  }
}

@mixin disabled-styles() {
  opacity: $disabled-opacity;
  pointer-events: none !important;
}

@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin animation-classes ($num, $width) {
  .animation-#{$width} {
    @include keyframes(animate-progress#{$width}) {
      0% { width: ($width - 5) * 1%; }
      100% { width: $width * 1%; }
    }

    width: $width * 1%;
    animation: animate-progress#{$width} 3s ease-in-out;
  }
}

$animation-percentage: (5: 5, 10: 10, 15: 15, 20: 20, 25: 25, 30: 30, 35: 35, 40:40, 45: 45, 50: 50, 55: 55, 60: 60, 65: 65, 70: 70, 75: 75, 80: 80, 85: 85, 90: 90, 95: 95, 100: 100);

@each $animation-num, $animation-width in $animation-percentage {
  @include animation-classes($animation-num, $animation-width);
}

// Source: https://stackoverflow.com/questions/7492062/css-overflow-scroll-always-show-vertical-scroll-bar
@mixin always-show-scrollbar() {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $gray-medium-light;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
